import store from '@/store';
import { defaultRealisationStatusFilters } from '@/config';
import { chargingPointStatuses } from '@/../shared/valueholders/chargingPointStatuses';
import { periodInDays } from '@/helpers/date';

const config = {
  chargePointOperatorField: {
    type: 'string',
    title: 'CPO',
    format: 'select',
    options: [
      { value: 'Vattenfall', text: 'Vattenfall' },
      { value: 'TotalEnergies', text: 'TotalEnergies' },
      { value: 'Equans', text: 'Equans' }
    ],
    disabled: false,
    placeholder: 'Selecteer CPO'
  },

  parkinglotSearch: true,

  // Enable the possibility to reset or cancel already saved date
  canResetDateValue: false,

  gridConnectionValues: [
    '1 x 25 A',
    '1 x 35 A',
    '1 x 40 A',
    '3 x 25 A',
    '3 x 35 A',
    '3 x 50 A',
    '3 x 63 A',
    '3 x 80 A',
    '3 x 100 A',
    '3 x 125 A',
    '3 x 160 A',
    '3 x 250 A',
    '3 x 400 A',
    '3 x 500 A',
    '3 x 630 A',
    '160 kVA',
    '250 kVA',
    '400 kVA',
    '630 kVA',
    '1750 kVA',
    '2000 kVA'
  ],

  /**
   * Steps
   */
  dataLayers: {
    'parking-permit-areas': {
      active: true,
      url: 'mapbox://evtools.nl_g_0363_parkeerzones',
      source: 'nl_g_0363_parkeerzones'
    }
  },

  defaultRealisationTableFields: isUserCPO => {
    return [
      { field: 'reference', label: 'Referentie', width: 192 },
      { field: 'street', label: 'Straat', width: 300 },
      { field: 'houseNumber', label: 'Huisnummer', width: 140, filterDisabled: true },
      { field: 'houseNumberSuffix', label: 'Toevoeging', width: 140, filterDisabled: true },
      { field: 'postalCode', label: 'Postcode', width: 160 },
      { field: 'address', label: 'Adres', width: 500 },

      { field: 'lat', label: 'Latitude', width: 192, filterDisabled: true },
      { field: 'lng', label: 'Longitude', width: 192, filterDisabled: true },
      { field: 'step', label: 'Stap', width: 340, fn: 'setStepLabels', matchMode: 'filterByArrayEquals', },
      { field: 'progress_days', label: 'In deze stap', width: 192, filterDisabled: true },
      {
        field: 'cpo',
        label: 'CPO',
        width: 192,
        fn: 'setCpoOptions',
        matchMode: 'filterByArrayEquals',
        filterDisabled: isUserCPO
      },
      {
        field: 'chargingpointStatus',
        label: 'Laadpaal status',
        width: 192,
        fn: 'setChargingpointStatuses',
        matchMode: 'filterByArrayEquals',
      },
      { field: 'currentStatus', label: 'Status', width: 132, fn: 'setStatusOptions', matchMode: 'filterByArrayEquals', filterDisabled: true },
      { field: 'currentStatusMessage', label: 'Onhold & Annulerings reden', width: 544, filterDisabled: true },
      { field: 'parkinglotIds', label: 'Parkeerplaatsen', width: 260, filterDisabled: true },
      { field: 'requestCount', label: 'Aanvragen', width: 192, filterDisabled: true },
      {
        field: 'category',
        label: 'Type',
        width: 192,
        fn: 'setCategoryOptions',
        matchMode: 'filterByArrayEquals'
      },
      { field: 'numberOfChargingStations', label: 'Aantal laadpalen', width: 192, filterDisabled: true },
      { field: 'vkb', label: 'VKB Status', width: 192, fn: 'setVkbStatusOptions', matchMode: 'filterByArrayEquals' },
      { field: 'VKBPublicationDate', label: 'Datum VKB publicatie', width: 256, filterDisabled: true },
      { field: 'VKBDefinitiveDate', label: 'Datum VKB definitief', width: 236, filterDisabled: true },
      { field: 'gridOperatorNumber', label: 'Netbeheerdernummer', width: 244, filterDisabled: true },
      { field: 'connectionValue', label: 'Aansluitwaarde', width: 192, fn: 'setGridConnectionValues', matchMode: 'filterByArrayEquals' },
      { field: 'EAN', label: 'EAN', width: 192, filterDisabled: true },
      { field: 'chargerID', label: "Laadpaal ID's", width: 192, filterDisabled: true },
      { field: 'realisationStartDate', label: 'Realisatie startdatum', width: 248, filterDisabled: true },
      { field: 'gridConnectionDate', label: 'Datum aanvraag netaansluiting', width: 317, filterDisabled: true },
      { field: 'realisation_date', label: 'Geplande realisatiedatum', width: 278, filterDisabled: true },
      { field: 'updated_at', label: 'Gewijzigd op', width: 192, filterDisabled: true },
      { field: 'dateOfCommissioning', label: 'Datum in bedrijf genomen', width: 280, filterDisabled: true },
      { field: 'tagcount', label: 'Aantal tags', width: 192, filterDisabled: true },
      { field: 'tags', label: 'Tags', width: 322, fn: 'setTagOptions', matchMode: 'commaSeparatedArrayAND' }
    ];
  },

  realisationListFields: ({ record }) => {
    const processTypeLabelGetter = store.getters['tenant/getSelectOptionLabelByValue'];
    //const getContractorByUuidGetter = store.getters['tenant/getContractorByUuid']
    const tagLabels = store.getters['tenant/getRecordTagLabels']({ record }).join(', ');

    if (record.progress_days === '-' && record.vkb !== 'Verlopen') {
      const lastStepStart = record.flow.state.filter(step => step.started_at).at(-1)?.started_at;
      const duration = {
        start: lastStepStart,
        end: 0,
        finished: false
      };
      record.progress_days = periodInDays({ duration });
    }

    const progressDays =
      typeof record.progress_days === 'number'
        ? record.progress_days + (record.progress_days === 1 ? ' dag' : ' dagen')
        : '-';

    const filters = record.categories;
    Object.keys(record.categories).forEach(filter => {
      filters[filter] ? defaultRealisationStatusFilters[filter].count++ : null;
    });

    return {
      uuid: { value: record.uuid, hidden: true },
      status: { value: record.categories, hidden: true },
      updated_at_nr: { value: record.updated_at_nr, hidden: true },
      reference: { value: record.reference, link: record.uuid },
      street: { value: record.street },
      houseNumber: { value: record.houseNumber },
      houseNumberSuffix: { value: record.houseNumberSuffix },
      postalCode: { value: record.postalCode },
      address: { value: record.address },
      lat: { value: record.lat },
      lng: { value: record.lng },
      step: { value: record.step },
      progress_days: { value: progressDays },
      chargingpointStatus: {
        value: chargingPointStatuses({ status: record.chargingpointStatus }),
        icon: record.chargingpointStatus
      },
      chargerID: { value: record.chargerIds },
      currentStatus: { value: record.currentStatus },
      cpo: { value: record.cpo },
      parkinglotIds: { value: record.parkinglotIds },
      currentStatusMessage: { value: record.currentStatusMessage },
      requestCount: { value: record.requestUuids.length },
      category: { value: processTypeLabelGetter({ name: 'processCategories', value: record.category }) },
      numberOfChargingStations: { value: record.numberOfChargingStations },
      vkb: { value: record.vkb },
      VKBPublicationDate: { value: record.VKBPublicationDate, fn: 'setDate' },
      VKBDefinitiveDate: { value: record.VKBDefinitiveDate, fn: 'setDate' },
      gridOperatorNumber: { value: record.gridOperatorNumber },
      connectionValue: { value: record.connectionValue },
      EAN: { value: record.EAN },
      realisationStartDate: { value: record.realisationStartDate, fn: 'setDate' },
      gridConnectionDate: { value: record.gridConnectionDate, fn: 'setDate' },
      realisation_date: { value: record.realisation_date, fn: 'setDate' },
      updated_at: { value: record.updated_at_nr, fn: 'setDate' },
      dateOfCommissioning: { value: record.dateOfCommissioning, fn: 'setDate' },
      tagcount: { value: record.tagcount },
      tags: { value: tagLabels }
    };
  }
};

export default config;
