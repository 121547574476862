<template>
  <div class="RealisationProcessStatus">
    <h4 class="pb-1">Status van realisatieprocessen</h4>

    <GChart
      type="BarChart"
      :data="chartDataTable"
      :options="chartOptions"
      style="transform: scale(0.9, 0.9) translateY(-3em)"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts';
import { mapGetters } from 'vuex';

export default {
  name: 'RealisationProcessStatus',
  components: {
    GChart
  },
  computed: {
    ...mapGetters('realisations', ['records']),
    ...mapGetters('tenant', ['getWorkflowSpecByName', 'getOnHoldReasons', 'getCancelReasons']),

    /**
     * Bar chart visualisation options
     */
    chartOptions() {
      return {
        // layout
        height: 900,
        width: 1500,
        chartArea: { left: 400, top: 10, right: 20, bottom: 100 },

        // other
        isStacked: false,
        legend: { position: 'bottom' },

        // axes
        hAxis: {
          title: ''
        },

        annotations: {
          textStyle: {
            color: 'black'
          },
          alwaysOutside: true,
          stem: {
            color: 'transparent'
          }
        }
        // orientation

        // TODO: Apply stack colors
        // series: {
        //   0: { color: 'green' },
        //   1: { color: 'blue' },
        //   // etc.
        // }
      };
    },

    steps() {
      let spec = this.getWorkflowSpecByName({ name: 'realisation' });
      return spec ? spec.steps : [];
    },

    /**
     *
     */
    availableSteps() {
      return this.steps.map(step => {
        return {
          value: step.uuid,
          label: `${step.short || step.step}: ${step.label}`,
          count: 0
        };
      });
    },

    availableOnHoldStates() {
      return this.getOnHoldReasons.map(reason => {
        return {
          value: reason.value,
          label: `On hold: ${reason.text}`,
          count: 0
        };
      });
    },
    availableCancelStates() {
      return [
        {
          value: 'municipality-feedback',
          label: 'Geannuleerd: gemeente feedback',
          count: 0
        }
      ].concat(
        this.getCancelReasons.map(reason => {
          return {
            value: reason.value,
            label: `Geannuleerd: ${reason.text}`,
            count: 0
          };
        })
      );
    },

    chartDataTableHeaderRow() {
      // steps, on hold reasons, cancelled reasons, afgerond

      // Status options, obtained from the tenant config.
      // let stepLabels = steps.map(step => `${step.short || step.step}: ${step.label}`)
      // let onHoldLabels = this.availableOnHoldStates.map(reason => reason.text)
      // let cancelLabels = this.availableCancelStates.map(reason => reason.text)

      // // Each status option is a data column in the data table
      // let headerRow = stepLabels.concat(onHoldLabels).concat(cancelLabels)
      // headerRow.push('Afgerond')

      // TODO: Add columns to subdivide "aantal" into count per # location proposals
      return ['Status', 'Aantal processen', { role: 'annotation' }, 'Aantal laadpalen', { role: 'annotation' }];
    },

    /**
     * The data for rending a graph is provided in table format
     *  For a bar chart, the first row in the datatable constains the labels,
     *  but also the role of the column in the datatable. This is not to be confused with the actual bars.
     */
    chartDataTable() {
      let RecordsByStep = {};
      let RecordsByCancelReason = {};
      let RecordsByOnHoldReason = {};
      let RecordsCompleted = 0;
      let unknown = 0;

      let RecordsCompletedChargerCount = 0;
      let unknownChargerCount = 0;

      this.records.forEach(record => {
        let status = record.status;
        let meta = record.statusMeta;

        let NumberOfChargingStations = Number(record?.CurrentLocation?.ParkingSpots?.NumberOfChargingStations) || 0

        if (status.completed) {
          RecordsCompleted++;
          RecordsCompletedChargerCount = RecordsCompletedChargerCount + NumberOfChargingStations;
        } else if (status.cancelled) {
          let reason = meta.cancelled.reason || 'unknown';
          RecordsByCancelReason[reason] = RecordsByCancelReason[reason] || 0;
          RecordsByCancelReason[reason]++;
          RecordsByCancelReason[reason + '-chargerCount'] =
            (RecordsByCancelReason[reason + '-chargerCount'] || 0) + NumberOfChargingStations;
        } else if (status.onhold) {
          let reason = meta.onhold.reason || 'unknown';
          RecordsByOnHoldReason[reason] = RecordsByOnHoldReason[reason] || 0;
          RecordsByOnHoldReason[reason]++;
          RecordsByOnHoldReason[reason + '-chargerCount'] =
            (RecordsByOnHoldReason[reason + '-chargerCount'] || 0) + NumberOfChargingStations;
        } else if (status.uuid) {
          RecordsByStep[status.uuid] = RecordsByStep[status.uuid] || 0;
          RecordsByStep[status.uuid]++;
          RecordsByStep[status.uuid + '-chargerCount'] =
            (RecordsByStep[status.uuid + '-chargerCount'] || 0) + NumberOfChargingStations;
        } else {
          unknown++;
          unknownChargerCount = unknownChargerCount + NumberOfChargingStations;
        }
      });

      // console.log(
      //   RecordsByStep,
      //   RecordsByCancelReason,
      //   RecordsByOnHoldReason,
      //   RecordsCompleted
      // )

      // Set the header row as first row in our data table
      const headerRow = this.chartDataTableHeaderRow;
      let chartDataTable = [headerRow];

      this.availableSteps.forEach(state => {
        let row = [state.label];
        row.push(RecordsByStep[state.value] || 0);
        row.push(RecordsByStep[state.value] || 0);
        row.push(RecordsByStep[state.value + '-chargerCount'] || 0);
        row.push(RecordsByStep[state.value + '-chargerCount'] || 0);
        chartDataTable.push(row);
      });

      this.availableCancelStates.forEach(state => {
        let row = [state.label];
        row.push(RecordsByCancelReason[state.value] || 0);
        row.push(RecordsByCancelReason[state.value] || 0);
        row.push(RecordsByCancelReason[state.value + '-chargerCount'] || 0);
        row.push(RecordsByCancelReason[state.value + '-chargerCount'] || 0);
        chartDataTable.push(row);
      });

      this.availableOnHoldStates.forEach(state => {
        let row = [state.label];
        row.push(RecordsByOnHoldReason[state.value] || 0);
        row.push(RecordsByOnHoldReason[state.value] || 0);
        row.push(RecordsByOnHoldReason[state.value + '-chargerCount'] || 0);
        row.push(RecordsByOnHoldReason[state.value + '-chargerCount'] || 0);
        chartDataTable.push(row);
      });

      chartDataTable.push([
        'Afgerond',
        RecordsCompleted,
        RecordsCompleted,
        RecordsCompletedChargerCount,
        RecordsCompletedChargerCount
      ]);

      console.log('Records with unknown status', unknown, unknownChargerCount);

      return chartDataTable;
    }
  }
};
</script>

<style></style>
