/* eslint-disable no-unused-vars */
/**
 * Import Dependency
 */
import ChargerLocation from '@/models/ChargerLocation'

/**
 * Import API
 */


const ChargingPointsByUUID = {}

/**
 * Declare Variable
 */
const state = {
  activeMunicipality: '',
  chargingpoints: [],
  initialChargingpoints: [],
  activeFilterItems: {
    municipalityCode: null,
    items: {
      chargingpoints: [],
      realisations: [],
      requests: [],
    },
    selectedFilterValues: null,
  },
  loadedChargingpoints: [],
}

const getters = {
  /**
   * Chargingpoints
   */
  getActiveMunicipality: (state) => state.activeMunicipality,
  chargingpoints: (state) => state.chargingpoints,
  getInitialChargingpoints: (state) => state.initialChargingpoints,
  chargingpointsByCode: (state) => ({ code }) => state.initialChargingpoints.filter(point => point.data.code === code),
  getChargingPointByUUID: (state) => ({ uuid }) => {
    if (! uuid) return null

    if (ChargingPointsByUUID[uuid]) {
      return ChargingPointsByUUID[uuid]
    }

    let match = state.chargingpoints.find(charger => {
      return charger.data.uuid === uuid
    })
    return match ? match : null
  },
  getActiveFilterItems: (state) => state.activeFilterItems.items,
  getSelectedFilterValues: (state) => state.activeFilterItems.selectedFilterValues || {},
}
const actions = {
  setActiveMunicipality ({ commit }, { code }) {
    commit('setActiveMunicipality', { code })
  },
  loadActiveFilterItems ({ commit }, { filterType, filterItems, municipalityCode }) {
    commit('setActiveFilterItems', { filterType, filterItems, municipalityCode })
  },
  loadSelectedFilterValues ({ commit, dispatch }, { filterValues }) {
    if (!filterValues) {
      // reset requests to initial state //
      dispatch('requests/resetInitialRequests', {}, { root: true })
    }
    commit('setSelectedFilterValues', { filterValues })
  },
  changeChargingpointStatus ({ commit }, { uuid, status }) {
    commit('setChargingpointStatus', { uuid, status })
  },
  loadChargingPointsFromStorage({ commit }, chargingpoints) {
    commit('setChargingPoints', chargingpoints)
  },

  loadWorkflowLocations ({ commit }, boolean) {
    commit('setWorkflowLocations', boolean)
  },

  putCreatedChargingpoint ({ commit }, chargingpoint) {
    commit('setCreatedChargingpoint', { chargingpoint })
  },

  async loadChargingPointsByCode({ commit, dispatch }, { code, after, isRealisationPrivate }) {
    const token = await this.$auth.getTokenSilently()
    const api = await fetch('/api/chargingpoints', {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        codes: [code],
        after,
        isRealisationPrivate
      }),
    })
    if (! api.ok) {
      throw new Error('failed')
    }
    const response = await api.json()

    if (response.data?.chargingpoints) {
      commit('setChargingPoints', { chargingpoints: response.data.chargingpoints })
    }

    if (response.data.after) {
      return await dispatch('loadChargingPointsByCode', { code, after: response.data.after, isRealisationPrivate })
    }

    return response
  },

  async deleteChargingPoint ({ commit }, { ref }) {
    const token = await this.$auth.getTokenSilently()
    const api = await fetch('/api/chargingpoints', {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        ref,
      }),
    })
    if (! api.ok) {
      throw new Error('Could not delete location!')
    }
    const response = await api.json()

    commit('deleteChargingPoint', { ref })

    return response
  },

  filterChargingPoints ({ commit, rootGetters }, filters) {
    const realisations = rootGetters['realisations/records']
    commit('setFilteredChargingPoints', { filters, realisations })
  },
  updateChargingpoint ({ commit }, { location }) {
    commit('removeLocation', { location })
  },
}
const mutations = {
  /**
   * Chargingpoints
   */
  removeLocation (state, { location }) {
    if (location) {
      const index = state.chargingpoints.findIndex(current => current.data.uuid === location.uuid)
      if (index !== -1) {
        state.chargingpoints.splice(index, 1)
      }

      const locationIndex = state.initialChargingpoints.findIndex(current => current.data.uuid === location.uuid)
      if (locationIndex !== -1) {
        state.initialChargingpoints.splice(locationIndex, 1)
      }
    }
  },

  setActiveMunicipality (state, { code }) {
    state.activeMunicipality = code
  },

  setChargingPoints(state, { chargingpoints }) {
    state.chargingpoints = [...state.chargingpoints, ...chargingpoints.map(data => new ChargerLocation({ data }))]
    state.initialChargingpoints = state.chargingpoints
  },

  setChargingpointStatus (state, { uuid, status }) {
    const recordIndex = state.chargingpoints
      .findIndex(current => current.data.uuid === uuid)

    if (recordIndex !== -1) {
      state.chargingpoints[recordIndex].data.properties.status = status
    }
  },

  /**
   * Replace all chargingpoints for a municipality by municipality code
   *  If none are set, it simply adds the additional chargingpoints to the collection
   */
  replaceChargingPointsByCode(state, { chargingpoints, code }) {
    const newChargingPoints = chargingpoints
      .map(location => new ChargerLocation({ data: location }))

    state.chargingpoints = (state.chargingpoints || [])
      .filter(location => location.data.code !== code)
      .concat(
        newChargingPoints,
      )

    newChargingPoints.forEach(location => {
      let uuid = location.data.uuid
      ChargingPointsByUUID[uuid] = location
    })

    state.initialChargingpoints = state.chargingpoints
  },

  replaceLocation(state, { location }) {
    const recordIndex = state.chargingpoints.findIndex(current => current.data.uuid === location.data.uuid)

    if (recordIndex !== -1) {
      let newLocation = new ChargerLocation({ data: location })
      state.chargingpoints.splice(recordIndex, 1, newLocation)

      let uuid = newLocation.data.uuid
      ChargingPointsByUUID[uuid] = newLocation
    } else {
      const cp = new ChargerLocation({ data: location })
      state.chargingpoints.push(cp)
      // If new CP is created without correction, remove RP properties from previous CP
      const previousConnectedCP = state.chargingpoints.findIndex(current => current.data.workflowUuid === location.data.workflowUuid)
      if (previousConnectedCP !== -1) {
        delete state.chargingpoints[previousConnectedCP].data.workflowUuid
        delete state.chargingpoints[previousConnectedCP].data.workflowCaseRef
      }
    }
    state.initialChargingpoints = state.chargingpoints
  },

  setCreatedChargingpoint (state, { chargingpoint }) {
    const cp = new ChargerLocation({ data: chargingpoint })
    if (!state.chargingpoints.some(chargingpoint => chargingpoint.data.uuid === cp.data.uuid)) {
      state.chargingpoints.push(cp)
    }
  },

  resetLocationStatus (state, { uuid }) {
    let recordIndex = state.chargingpoints
      .findIndex(current => current.data.uuid === uuid)

    const isWorkflowCreated = state.chargingpoints[recordIndex].data.properties.isWorkflowCreated
    if (recordIndex !== -1) {
      const privateType = state.chargingpoints[recordIndex].data.properties.status.includes('private') ? '-private' : ''
      state.chargingpoints[recordIndex].data.properties.status = (isWorkflowCreated ? 'suggestion' : 'definitive') + privateType
    }
  },

  deleteChargingPoint (state, { ref }) {
    let recordIndex = state.chargingpoints
      .findIndex(current => current.ref['@ref'].id === ref)

    if (recordIndex !== -1) {
      state.chargingpoints.splice(recordIndex, 1)
    }
  },

  setWorkflowLocations (state, boolean) {
    state.chargingpoints = boolean
      ? state.chargingpoints.filter(chargingpoint => {
        return chargingpoint.data.properties.isWorkflowCreated && chargingpoint.data.address.source === process.env.VUE_APP_TENANT
      })
      : state.initialChargingpoints
  },

  setFilteredChargingPoints (state, { filters, realisations }) {
    const values = Object.values(filters).some(filter => Array.isArray(filter) && filter.length)
    const CPtypes = filters.chargingpoints?.map(cp => cp.type || cp.prop)
    const RPtypes = filters.realisations.map(s => s.type)

    state.chargingpoints = state.initialChargingpoints.filter(cp => cp.data.code === filters.activeMunicipality)

    let filteredRPs = []
    if (filters.realisations.length) {
      filteredRPs = realisations.filter(realisation => {
        const statuses = ['completed', 'cancelled', 'onhold']
        const isActive = RPtypes.includes('active') && statuses.every(status => !realisation.status[status])
        return isActive || RPtypes.some(type => !!realisation.status[type])
      })
    }

    if (values && !CPtypes.length) {
      // If RP filter is present, return status as well
      return state.chargingpoints = filteredRPs.length ? state.chargingpoints.filter(cp => filteredRPs.some(rp => rp.uuid === cp.data.workflowUuid)) : []
    }

    state.chargingpoints = CPtypes?.length
      ? state.chargingpoints.filter(chargingpoint => {
        const selectedRps = filteredRPs.some(rp => rp.uuid === chargingpoint.data.workflowUuid)

        return selectedRps || CPtypes.includes(chargingpoint.data.properties.status) || CPtypes.some(prop => chargingpoint.data[prop])
      })
      : state.initialChargingpoints.filter(cp => cp.data.code === filters.activeMunicipality)
  },

  setActiveFilterItems (state, { filterType, filterItems, municipalityCode }) {
    state.activeFilterItems.items[filterType] = filterItems
    state.activeFilterItems.municipalityCode = municipalityCode
  },

  setSelectedFilterValues (state, { filterValues }) {
    state.activeFilterItems.selectedFilterValues = filterValues
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
