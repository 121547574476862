<template>
  <MapBoxPopup
    :coordinates="coordinates"
    :show="show"
    :offset="[0, -30]"
    @close="handleClose"
  >
    <div>
      <div
        v-for="chargingPoint in visibleChargingPoints"
        :key="chargingPoint ? chargingPoint.uuid : 'none'"
        class="d-flex flex-column mb-1"
      >
        <div v-if="chargingPoint">
          <div v-if="chargingPoint.address">
            {{ chargingPoint.address }}
          </div>
          <div>
            {{ chargingPoint.statusLabel }}
          </div>
          <div>EVtools ID: {{ `${chargingPoint.code}-${chargingPoint.id}` }}</div>
          <div>CPO: {{ chargingPoint.cpo || 'Geen' }}</div>
          <div v-if="chargingPoint.meta.source === 'import-royal-haskoning'">
            Type: {{ chargingPoint.meta.type }}
          </div>
          <div v-if="chargingPoint.meta.voltID">
            VOLT locatie: {{ chargingPoint.meta.voltID }}
          </div>
          <div v-if="chargingPoint.meta.phase">
            Fase: {{ chargingPoint.meta.phase }}
          </div>
          <div v-if="chargingPoint.parkinglotIds">
            Parkeervakken: {{ chargingPoint.parkinglotIds }}
          </div>
          <div
            v-if="!locked"
            class="mt-2"
          >
            <a
              v-if="chargingPoint.selected && !chargingPoint.isAsset && hasWorkflowUuid({ uuid: chargingPoint.uud })"
              href=""
              @click="handleSelectLocation({ uuid: null }, $event)"
            >
              <b-icon icon="x-circle" /> Ontkoppel deze locatie
            </a>
            <a
              v-else-if="canBeSelected({ chargingPoint })"
              href=""
              @click="handleSelectLocation({ uuid: chargingPoint.uuid }, $event)"
            >
              <b-icon icon="geo-fill" /> Gebruik deze locatie
            </a>
          </div>
          <div
            v-if="isRealisationLocation({ chargingPoint })"
            class="mt-2"
          >
            Deze locatie is verbonden aan dit proces
          </div>

          <router-link
            v-if="hasWorkflowUuid({ uuid: chargingPoint.workflowUuid })"
            :to="getProcessRoute({ chargingPoint })"
            class="d-flex align-items-center link-rp"
          >
            Bekijk het proces <strong>{{ chargingPoint.workflowCaseRef }}</strong>
            <img
              v-if="chargingPoint.isRealisationCancelled"
              :src="getImage('cancelled')"
              width="10"
            >
          </router-link>
          <div
            v-if="chargingPoint.remark"
            class="mt-3"
          >
            {{ chargingPoint.remark }}
          </div>
          <button
            v-if="canLocationBeDeleted({ chargingPoint })"
            type="button"
            class="btn btn-outline-danger btn-sm mt-3 w-100"
            @click="handleDeleteChargingPoint({ chargingPoint })"
          >
            Verwijder deze locatie
          </button>
          <div
            v-if="canAccessDebugger"
            class="mt-3"
          >
            UUID: {{ chargingPoint.uuid }}
          </div>
        </div>
      </div>

      <b-pagination
        v-if="chargingPoints.length > 1"
        v-model="currentPage"
        class="justify-content-center align-items-center pagination-sm mt-3 mb-0"
        hide-goto-end-buttons
        :total-rows="chargingPoints.length"
        :per-page="1"
      />
    </div>
  </MapBoxPopup>
</template>

<script>
import MapBoxMixin from '@/components/common/MapBoxMixin.vue';
import MapBoxPopup from '@/components/common/MapBoxPopup';
import { mapGetters, mapActions } from 'vuex';
import { chargingPointStatuses } from '@/../shared/valueholders/chargingPointStatuses';

export default {
  name: 'PopupChargingLocation',
  components: {
    MapBoxPopup
  },
  mixins: [MapBoxMixin],
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    locked: {
      type: Boolean,
      default: true
    },
    selected: {
      type: String,
      default: null
    },
    alternative: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      layerName: 'chargingpoints',
      show: false,
      uuids: [],
      currentPage: 1,
      processType: null
    };
  },
  computed: {
    ...mapGetters('chargingpoints', ['getChargingPointByUUID']),
    ...mapGetters('realisations', ['recordByUuid', 'records']),
    ...mapGetters('user', ['securityCheck']),
    ...mapGetters('tenant', ['getCpos', 'getProcessTypes']),

    chargingPoints() {
      const uuids = [...new Set(this.uuids)]; // remove possible duplicate uuid's
      return uuids
        .map(uuid => this.getChargingPointByUUID({ uuid }))
        .map(chargingPoint => {
          if (!chargingPoint) return;

          let data = chargingPoint.data;
          let status = data.properties.status;
          let location = {
            uuid: data.uuid,
            coordinates: data.coordinates,
            address: this.formatAddress(data),
            status,
            cpo: data.properties.stakeholders?.[0]?.name || data.properties.operator,
            statusLabel: chargingPointStatuses({ status: status.replace('-alternative', '') }),
            id: data.properties.id,
            code: data.code,
            remark: !data.properties.remark?.includes(data.properties.workflowCaseRef) ? data.properties.remark : '',
            isWorkflowCreated: data.properties.isWorkflowCreated || data.isWorkflowCreated,
            stakeholders: data.properties.stakeholders,
            ref: chargingPoint.ref['@ref']?.id || chargingPoint.ref.value.id,
            meta: {
              source: null,
              type: null
            },
            selected: data.uuid === this.selected,
            workflowUuid: data.workflowUuid,
            workflowCaseRef: data.workflowCaseRef,
            isAsset: data.properties.isAsset
          };

          const linkedToCancelledRealisation = this.records.find(
            rp => rp.status.cancelled && rp.CurrentLocation.Location.chargingpointUuid === location.uuid
          );

          if (linkedToCancelledRealisation) {
            location.workflowUuid = linkedToCancelledRealisation.uuid;
            location.workflowCaseRef = linkedToCancelledRealisation.case_ref;
            location.isRealisationCancelled = true;
          }

          if (data.isWorkflowAltLocation) {
            location.isWorkflowAltLocation = data.isWorkflowAltLocation;
            location.remark = `Geselecteerd als alternatief (${data.workflowCaseRef})`;
          }

          if (data.properties.parkinglotIds) {
            location.parkinglotIds = data.properties.parkinglotIds.join(', ');
          }

          // Royal Haskoning Special
          if (data.meta && data.meta.source === 'import-royal-haskoning') {
            let proactive = chargingPoint.getPhaseInformation();

            location.meta.source = data.meta.source;
            location.meta.type = proactive === null ? 'Regulier' : 'Proactief';
            location.meta.phase = proactive === null ? '' : proactive.full;
            location.meta.voltID = data.meta.import?.voltID;
          }

          return location;
        });
    },
    realisationHasLocation() {
      return this.record.CurrentLocation.Location?.chargingpointUuid;
    },
    visibleChargingPoints() {
      return [this.chargingPoints[this.currentPage - 1] || false];
    },
    coordinates() {
      return this.chargingPoints?.[0]?.coordinates || [0, 0];
    },
    hasRequiredInformation() {
      return this.chargingPoints?.length !== 0;
    },
    canAccessDebugger() {
      return this.securityCheck({
        attribute: 'canAccessDebugger'
      });
    },
    canDeleteChargingPoint() {
      return this.securityCheck({
        attribute: 'canDeleteProcess'
      });
    },
    isStep1() {
      return /Step1$/.test(this.$route.name);
    },
    tenantCpos() {
      return Object.keys(this.getCpos).map(cpo => this.getCpos[cpo].uuid);
    },
    statusType () {
      return this.record.category === 'private' ? '-private' : ''
    }
  },
  watch: {
    hasRequiredInformation(hasRequiredInformation) {
      this.show = hasRequiredInformation;
    }
  },
  created() {
    this.bind();
  },
  beforeDestroy() {
    this.unbind();
  },
  methods: {
    ...mapActions('chargingpoints', ['deleteChargingPoint']),

    getImage(name) {
      return require(`@/assets/image/legend/realisation-${name}.png`);
    },

    getProcessRoute ({ chargingPoint }) {
      const caseRef = chargingPoint.workflowCaseRef.split('-')
      this.processType = this.getProcessTypes.find(type => caseRef.includes(type.acronym))
      const name =`${this.processType.value}Step1`

      const route = {
        name,
        params: {
          uuid: chargingPoint.workflowUuid,
          step: '1',
          type: this.processType.routeType
        }
      }

      return route
    },

    formatAddress(data) {
      // Vlaanderen doesn't have formated_address, import_meta.label used in stead //
      return (data.address?.formatted_address + '').replace(', Nederland', '') || data.import_meta?.label;
    },
    bind() {
      if (!this.map) return;

      // click event
      this.map.on('click', this.layerName, this.handleLayerClickEvent);
      this.map.on('click', `${this.layerName}-text`, this.handleLayerClickEvent);

      // Cursor
      this.map.on('mouseenter', this.layerName, this.showPointer);
      this.map.on('mouseenter', `${this.layerName}-text`, this.showPointer);
      this.map.on('mouseleave', this.layerName, this.hidePointer);
      this.map.on('mouseleave', `${this.layerName}-text`, this.hidePointer);
    },
    unbind() {
      if (!this.map) return;

      this.map.off('click', this.layerName, this.handleLayerClickEvent);
      this.map.off('click', `${this.layerName}-text`, this.handleLayerClickEvent);

      this.map.off('mouseenter', this.layerName, this.showPointer);
      this.map.off('mouseenter', `${this.layerName}-text`, this.showPointer);
      this.map.off('mouseleave', this.layerName, this.hidePointer);
      this.map.off('mouseleave', `${this.layerName}-text`, this.hidePointer);
    },

    isRealisationLocation({ chargingPoint }) {
      const uuid = this.$route.params.uuid;
      return uuid && chargingPoint.workflowUuid === uuid && !chargingPoint.isRealisationCancelled;
    },

    canBeSelected({ chargingPoint }) {
      return (
        !chargingPoint.isWorkflowAltLocation &&
        ['suggestion', 'definitive'].includes(chargingPoint.status) &&
        !chargingPoint.workflowUuid &&
        !this.selected &&
        !chargingPoint.meta?.voltID &&
        (!chargingPoint.stakeholders?.length ||
          chargingPoint.stakeholders.some(
            stakeholder => stakeholder.type === 'cpo' && this.tenantCpos.includes(stakeholder.uuid)
          ))
      );
    },

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.map.getCanvas().style.cursor = 'pointer';
    },
    hidePointer() {
      this.map.getCanvas().style.cursor = '';
    },

    handleLayerClickEvent(e) {
      if (!e.features.length) return;

      // Cancel other map events
      e.preventDefault();
      e.originalEvent.stopPropagation();

      let features = e.features;

      this.$nextTick(() => {
        this.currentPage = 1;
        this.uuids = features.filter(feature => feature?.properties.uuid).map(feature => feature.properties.uuid);

        if (this.uuids.length) {
          this.show = true;
        }
      });
    },

    handleClose() {
      this.show = false;
      // this.uuids = []
    },

    handleSelectLocation({ uuid }, e) {
      e.preventDefault();
      this.$emit('location', { uuid });
      this.show = false;
    },

    hasWorkflowUuid({ uuid }) {
      return uuid && uuid !== this.$route.params.uuid;
    },

    handleDeleteChargingPoint({ chargingPoint }) {
      this.$bvModal
        .msgBoxConfirm('Bevestig het verwijderen van de locatie.', {
          okVariant: 'danger',
          okTitle: 'Bevestigen',
          cancelTitle: 'Annuleren'
        })
        .then(confirmed => {
          if (confirmed) {
            const ref = chargingPoint.ref;
            this.deleteChargingPoint({ ref });
            this.handleClose();
          }
        });
    },

    canLocationBeDeleted({ chargingPoint }) {
      if (!this.canDeleteChargingPoint) return

      const eligibleStatuses = /definitive|suggestion|rejected/;

      return (
        this.isStep1 &&
        chargingPoint.isWorkflowCreated &&
        eligibleStatuses.test(chargingPoint.status) &&
        !chargingPoint.isWorkflowAltLocation &&
        (!chargingPoint.workflowUuid || chargingPoint.isRealisationCancelled) &&
        chargingPoint.uuid !== this.selected &&
        !this.isAlternativeLocation({ chargingPoint })
      );
    },

    isWorkflowCreated({ source }) {
      return source === process.env.VUE_APP_TENANT;
    },

    isAlternativeLocation({ chargingPoint }) {
      return this.record.CurrentLocation.CPOAdvice?.Alternative?.Location?.chargingpointUuid === chargingPoint.uuid;
    }
  }
};
</script>
<style lang="scss">
.link-rp {
  gap: 0.3em;
}
</style>
