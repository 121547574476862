
export const allMunicipalityOptions = [
  {
      "value": "1680",
      "text": "Aa en Hunze",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0358",
      "text": "Aalsmeer",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "9c94c463-0dc3-412d-ac1d-ee331442cb55"
  },
  {
      "value": "0197",
      "text": "Aalten",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0059",
      "text": "Achtkarspelen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0482",
      "text": "Alblasserdam",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "0613",
      "text": "Albrandswaard",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0361",
      "text": "Alkmaar",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0141",
      "text": "Almelo",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0034",
      "text": "Almere",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0484",
      "text": "Alphen aan den Rijn",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "9c94c463-0dc3-412d-ac1d-ee331442cb55"
  },
  {
      "value": "1723",
      "text": "Alphen-Chaam",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1959",
      "text": "Altena",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "adcef5ae-90d7-4085-94a8-3f1d330dcab0"
  },
  {
      "value": "0060",
      "text": "Ameland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0307",
      "text": "Amersfoort",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "0362",
      "text": "Amstelveen",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "9c94c463-0dc3-412d-ac1d-ee331442cb55"
  },
  {
      "value": "0363",
      "text": "Amsterdam",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0200",
      "text": "Apeldoorn",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0003",
      "text": "Appingedam",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0202",
      "text": "Arnhem",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0106",
      "text": "Assen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0743",
      "text": "Asten",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "adcef5ae-90d7-4085-94a8-3f1d330dcab0"
  },
  {
      "value": "0744",
      "text": "Baarle-Nassau",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0308",
      "text": "Baarn",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0489",
      "text": "Barendrecht",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0203",
      "text": "Barneveld",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0888",
      "text": "Beek",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1954",
      "text": "Beekdaelen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0889",
      "text": "Beesel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1945",
      "text": "Berg en Dal",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1724",
      "text": "Bergeijk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0893",
      "text": "Bergen (L.)",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0373",
      "text": "Bergen (NH.)",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0748",
      "text": "Bergen op Zoom",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1859",
      "text": "Berkelland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1721",
      "text": "Bernheze",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0753",
      "text": "Best",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0209",
      "text": "Beuningen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0375",
      "text": "Beverwijk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0310",
      "text": "De Bilt",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1728",
      "text": "Bladel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0376",
      "text": "Blaricum",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0377",
      "text": "Bloemendaal",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1901",
      "text": "Bodegraven-Reeuwijk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0755",
      "text": "Boekel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1681",
      "text": "Borger-Odoorn",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0147",
      "text": "Borne",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0654",
      "text": "Borsele",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0757",
      "text": "Boxtel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0758",
      "text": "Breda",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "adcef5ae-90d7-4085-94a8-3f1d330dcab0"
  },
  {
      "value": "0501",
      "text": "Brielle",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1876",
      "text": "Bronckhorst",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0213",
      "text": "Brummen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0899",
      "text": "Brunssum",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0312",
      "text": "Bunnik",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0313",
      "text": "Bunschoten",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0214",
      "text": "Buren",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0502",
      "text": "Capelle aan den IJssel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0383",
      "text": "Castricum",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0109",
      "text": "Coevorden",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1706",
      "text": "Cranendonck",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0216",
      "text": "Culemborg",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0148",
      "text": "Dalfsen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1891",
      "text": "Dantumadiel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0503",
      "text": "Delft",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0010",
      "text": "Delfzijl",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0762",
      "text": "Deurne",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0150",
      "text": "Deventer",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "98d631ce-805d-4c31-8d9d-70a8a2408183"
  },
  {
      "value": "0384",
      "text": "Diemen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1774",
      "text": "Dinkelland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0221",
      "text": "Doesburg",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0222",
      "text": "Doetinchem",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0766",
      "text": "Dongen",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "adcef5ae-90d7-4085-94a8-3f1d330dcab0"
  },
  {
      "value": "0505",
      "text": "Dordrecht",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "0498",
      "text": "Drechterland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1719",
      "text": "Drimmelen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0303",
      "text": "Dronten",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0225",
      "text": "Druten",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0226",
      "text": "Duiven",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "1711",
      "text": "Echt-Susteren",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0385",
      "text": "Edam-Volendam",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0228",
      "text": "Ede",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0317",
      "text": "Eemnes",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0770",
      "text": "Eersel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1903",
      "text": "Eijsden-Margraten",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0772",
      "text": "Eindhoven",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "adcef5ae-90d7-4085-94a8-3f1d330dcab0"
  },
  {
      "value": "0230",
      "text": "Elburg",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0114",
      "text": "Emmen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0388",
      "text": "Enkhuizen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0153",
      "text": "Enschede",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0232",
      "text": "Epe",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0233",
      "text": "Ermelo",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0777",
      "text": "Etten-Leur",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1940",
      "text": "De Fryske Marren",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0779",
      "text": "Geertruidenberg",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1771",
      "text": "Geldrop-Mierlo",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1652",
      "text": "Gemert-Bakel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0907",
      "text": "Gennep",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0784",
      "text": "Gilze en Rijen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1924",
      "text": "Goeree-Overflakkee",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "4f383631-cc18-49cb-a053-4543642b7f5b"
  },
  {
      "value": "0664",
      "text": "Goes",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0785",
      "text": "Goirle",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1942",
      "text": "Gooise Meren",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0512",
      "text": "Gorinchem",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0513",
      "text": "Gouda",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0518",
      "text": "'s-Gravenhage",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0014",
      "text": "Groningen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1729",
      "text": "Gulpen-Wittem",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0158",
      "text": "Haaksbergen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0788",
      "text": "Haaren",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0392",
      "text": "Haarlem",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0394",
      "text": "Haarlemmermeer",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1655",
      "text": "Halderberge",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0160",
      "text": "Hardenberg",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0243",
      "text": "Harderwijk",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0523",
      "text": "Hardinxveld-Giessendam",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "0072",
      "text": "Harlingen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0244",
      "text": "Hattem",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0396",
      "text": "Heemskerk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0397",
      "text": "Heemstede",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "0246",
      "text": "Heerde",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0074",
      "text": "Heerenveen",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "98d631ce-805d-4c31-8d9d-70a8a2408183"
  },
  {
      "value": "0917",
      "text": "Heerlen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1658",
      "text": "Heeze-Leende",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0399",
      "text": "Heiloo",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0400",
      "text": "Den Helder",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0163",
      "text": "Hellendoorn",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0530",
      "text": "Hellevoetsluis",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0794",
      "text": "Helmond",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0531",
      "text": "Hendrik-Ido-Ambacht",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "0164",
      "text": "Hengelo",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "98d631ce-805d-4c31-8d9d-70a8a2408183"
  },
  {
      "value": "0796",
      "text": "'s-Hertogenbosch",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0252",
      "text": "Heumen",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0797",
      "text": "Heusden",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0534",
      "text": "Hillegom",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0798",
      "text": "Hilvarenbeek",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0402",
      "text": "Hilversum",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1963",
      "text": "Hoeksche Waard",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1735",
      "text": "Hof van Twente",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1966",
      "text": "Het Hogeland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1911",
      "text": "Hollands Kroon",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0118",
      "text": "Hoogeveen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0405",
      "text": "Hoorn",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1507",
      "text": "Horst aan de Maas",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0321",
      "text": "Houten",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0406",
      "text": "Huizen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0677",
      "text": "Hulst",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0353",
      "text": "IJsselstein",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1884",
      "text": "Kaag en Braassem",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0166",
      "text": "Kampen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0678",
      "text": "Kapelle",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0537",
      "text": "Katwijk",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "9c94c463-0dc3-412d-ac1d-ee331442cb55"
  },
  {
      "value": "0928",
      "text": "Kerkrade",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1598",
      "text": "Koggenland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0542",
      "text": "Krimpen aan den IJssel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1931",
      "text": "Krimpenerwaard",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1659",
      "text": "Laarbeek",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0882",
      "text": "Landgraaf",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0415",
      "text": "Landsmeer",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1980",
      "text": "Dijk en Waard",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1621",
      "text": "Lansingerland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0417",
      "text": "Laren",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0080",
      "text": "Leeuwarden",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "cf5b0d18-b6e4-44e4-a305-d1f4db13381e"
  },
  {
      "value": "0546",
      "text": "Leiden",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "cf5b0d18-b6e4-44e4-a305-d1f4db13381e"
  },
  {
      "value": "0547",
      "text": "Leiderdorp",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "cf5b0d18-b6e4-44e4-a305-d1f4db13381e"
  },
  {
      "value": "1916",
      "text": "Leidschendam-Voorburg",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0995",
      "text": "Lelystad",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1640",
      "text": "Leudal",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0327",
      "text": "Leusden",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1705",
      "text": "Lingewaard",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0553",
      "text": "Lisse",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0262",
      "text": "Lochem",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0809",
      "text": "Loon op Zand",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "adcef5ae-90d7-4085-94a8-3f1d330dcab0"
  },
  {
      "value": "0331",
      "text": "Lopik",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0024",
      "text": "Loppersum",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0168",
      "text": "Losser",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0263",
      "text": "Maasdriel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1641",
      "text": "Maasgouw",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0556",
      "text": "Maassluis",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0935",
      "text": "Maastricht",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0420",
      "text": "Medemblik",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0938",
      "text": "Meerssen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1948",
      "text": "Meierijstad",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0119",
      "text": "Meppel",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "98d631ce-805d-4c31-8d9d-70a8a2408183"
  },
  {
      "value": "0687",
      "text": "Middelburg",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1842",
      "text": "Midden-Delfland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1731",
      "text": "Midden-Drenthe",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1952",
      "text": "Midden-Groningen",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "98d631ce-805d-4c31-8d9d-70a8a2408183"
  },
  {
      "value": "1709",
      "text": "Moerdijk",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "adcef5ae-90d7-4085-94a8-3f1d330dcab0"
  },
  {
      "value": "1978",
      "text": "Molenlanden",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1955",
      "text": "Montferland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0335",
      "text": "Montfoort",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0944",
      "text": "Mook en Middelaar",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1740",
      "text": "Neder-Betuwe",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0946",
      "text": "Nederweert",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0356",
      "text": "Nieuwegein",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "0569",
      "text": "Nieuwkoop",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "cf5b0d18-b6e4-44e4-a305-d1f4db13381e"
  },
  {
      "value": "0267",
      "text": "Nijkerk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0268",
      "text": "Nijmegen",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "1930",
      "text": "Nissewaard",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1970",
      "text": "Noardeast-Fryslân",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1695",
      "text": "Noord-Beveland",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "7bbfe594-ea4f-4966-a29d-f2bb1f68ec8b"
  },
  {
      "value": "1699",
      "text": "Noordenveld",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0171",
      "text": "Noordoostpolder",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0575",
      "text": "Noordwijk",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "9c94c463-0dc3-412d-ac1d-ee331442cb55"
  },
  {
      "value": "0820",
      "text": "Nuenen, Gerwen en Nederwetten",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0302",
      "text": "Nunspeet",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0579",
      "text": "Oegstgeest",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "cf5b0d18-b6e4-44e4-a305-d1f4db13381e"
  },
  {
      "value": "0823",
      "text": "Oirschot",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0824",
      "text": "Oisterwijk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1895",
      "text": "Oldambt",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0269",
      "text": "Oldebroek",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0173",
      "text": "Oldenzaal",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1773",
      "text": "Olst-Wijhe",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0175",
      "text": "Ommen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1586",
      "text": "Oost Gelre",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "cf5b0d18-b6e4-44e4-a305-d1f4db13381e"
  },
  {
      "value": "0826",
      "text": "Oosterhout",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0085",
      "text": "Ooststellingwerf",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0431",
      "text": "Oostzaan",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0432",
      "text": "Opmeer",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0086",
      "text": "Opsterland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0828",
      "text": "Oss",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1509",
      "text": "Oude IJsselstreek",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0437",
      "text": "Ouder-Amstel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0589",
      "text": "Oudewater",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1734",
      "text": "Overbetuwe",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0590",
      "text": "Papendrecht",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "1894",
      "text": "Peel en Maas",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0765",
      "text": "Pekela",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1926",
      "text": "Pijnacker-Nootdorp",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0439",
      "text": "Purmerend",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0273",
      "text": "Putten",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0177",
      "text": "Raalte",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0703",
      "text": "Reimerswaal",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0274",
      "text": "Renkum",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0339",
      "text": "Renswoude",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1667",
      "text": "Reusel-De Mierden",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0275",
      "text": "Rheden",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0340",
      "text": "Rhenen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0597",
      "text": "Ridderkerk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1742",
      "text": "Rijssen-Holten",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0603",
      "text": "Rijswijk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1669",
      "text": "Roerdalen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0957",
      "text": "Roermond",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0736",
      "text": "De Ronde Venen",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "1674",
      "text": "Roosendaal",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0599",
      "text": "Rotterdam",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0277",
      "text": "Rozendaal",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0840",
      "text": "Rucphen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0441",
      "text": "Schagen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0279",
      "text": "Scherpenzeel",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0606",
      "text": "Schiedam",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0088",
      "text": "Schiermonnikoog",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1676",
      "text": "Schouwen-Duiveland",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "4f383631-cc18-49cb-a053-4543642b7f5b"
  },
  {
      "value": "0965",
      "text": "Simpelveld",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0845",
      "text": "Sint-Michielsgestel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1883",
      "text": "Sittard-Geleen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0610",
      "text": "Sliedrecht",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "1714",
      "text": "Sluis",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0090",
      "text": "Smallingerland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0342",
      "text": "Soest",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0847",
      "text": "Someren",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0848",
      "text": "Son en Breugel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0037",
      "text": "Stadskanaal",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0180",
      "text": "Staphorst",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0532",
      "text": "Stede Broec",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0851",
      "text": "Steenbergen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1708",
      "text": "Steenwijkerland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0971",
      "text": "Stein",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1904",
      "text": "Stichtse Vecht",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1900",
      "text": "Súdwest-Fryslân",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0715",
      "text": "Terneuzen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0093",
      "text": "Terschelling",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0448",
      "text": "Texel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1525",
      "text": "Teylingen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0716",
      "text": "Tholen",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "4f383631-cc18-49cb-a053-4543642b7f5b"
  },
  {
      "value": "0281",
      "text": "Tiel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0855",
      "text": "Tilburg",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "adcef5ae-90d7-4085-94a8-3f1d330dcab0"
  },
  {
      "value": "0183",
      "text": "Tubbergen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1700",
      "text": "Twenterand",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1730",
      "text": "Tynaarlo",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0737",
      "text": "Tytsjerksteradiel",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0450",
      "text": "Uitgeest",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0451",
      "text": "Uithoorn",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0184",
      "text": "Urk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0344",
      "text": "Utrecht",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1581",
      "text": "Utrechtse Heuvelrug",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0981",
      "text": "Vaals",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0994",
      "text": "Valkenburg aan de Geul",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0858",
      "text": "Valkenswaard",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0047",
      "text": "Veendam",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0345",
      "text": "Veenendaal",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "0717",
      "text": "Veere",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "7bbfe594-ea4f-4966-a29d-f2bb1f68ec8b"
  },
  {
      "value": "0861",
      "text": "Veldhoven",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0453",
      "text": "Velsen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0983",
      "text": "Venlo",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0984",
      "text": "Venray",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1961",
      "text": "Vijfheerenlanden",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0622",
      "text": "Vlaardingen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0096",
      "text": "Vlieland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0718",
      "text": "Vlissingen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0986",
      "text": "Voerendaal",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0626",
      "text": "Voorschoten",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0285",
      "text": "Voorst",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "cf5b0d18-b6e4-44e4-a305-d1f4db13381e"
  },
  {
      "value": "0865",
      "text": "Vught",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1949",
      "text": "Waadhoeke",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0866",
      "text": "Waalre",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "adcef5ae-90d7-4085-94a8-3f1d330dcab0"
  },
  {
      "value": "0867",
      "text": "Waalwijk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0627",
      "text": "Waddinxveen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0289",
      "text": "Wageningen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0629",
      "text": "Wassenaar",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0852",
      "text": "Waterland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0988",
      "text": "Weert",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0457",
      "text": "Weesp",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1960",
      "text": "West Betuwe",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0668",
      "text": "West Maas en Waal",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1969",
      "text": "Westerkwartier",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1701",
      "text": "Westerveld",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0293",
      "text": "Westervoort",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1950",
      "text": "Westerwolde",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1783",
      "text": "Westland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0098",
      "text": "Weststellingwerf",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0614",
      "text": "Westvoorne",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0189",
      "text": "Wierden",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0296",
      "text": "Wijchen",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "cf5b0d18-b6e4-44e4-a305-d1f4db13381e"
  },
  {
      "value": "1696",
      "text": "Wijdemeren",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0352",
      "text": "Wijk bij Duurstede",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "0294",
      "text": "Winterswijk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0873",
      "text": "Woensdrecht",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0632",
      "text": "Woerden",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "1690",
      "text": "De Wolden",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0880",
      "text": "Wormerland",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0351",
      "text": "Woudenberg",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0479",
      "text": "Zaanstad",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0297",
      "text": "Zaltbommel",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0473",
      "text": "Zandvoort",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0050",
      "text": "Zeewolde",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "8de24014-bd1c-4b05-831a-61aa1ad2c51f"
  },
  {
      "value": "0355",
      "text": "Zeist",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0299",
      "text": "Zevenaar",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "53401a9c-5044-4a20-942e-89f8a2a500a9"
  },
  {
      "value": "0637",
      "text": "Zoetermeer",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0638",
      "text": "Zoeterwoude",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1892",
      "text": "Zuidplas",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0879",
      "text": "Zundert",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0301",
      "text": "Zutphen",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1896",
      "text": "Zwartewaterland",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "98d631ce-805d-4c31-8d9d-70a8a2408183"
  },
  {
      "value": "0642",
      "text": "Zwijndrecht",
      "disabled": false,
      "locationSelectionAllowed": true,
      "contractor": "743673f6-c2ef-48a7-9b20-c6bc42d7c1ef"
  },
  {
      "value": "0193",
      "text": "Zwolle",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1982",
      "text": "Land van Cuijk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1991",
      "text": "Maashorst",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0756",
      "text": "Boxmeer",
      "slug": "boxmeer",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1702",
      "text": "Sint Anthonis",
      "slug": "sint-anthonis",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1685",
      "text": "Landerd",
      "slug": "landerd",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0856",
      "text": "Uden",
      "slug": "uden",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0398",
      "text": "Heerhugowaard",
      "slug": "heerhugowaard",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0416",
      "text": "Langedijk",
      "slug": "langedijk",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "0370",
      "text": "Beemster",
      "slug": "beemster",
      "disabled": false,
      "locationSelectionAllowed": true
  },
  {
      "value": "1979",
      "text": "Eemsdelta",
      "slug": "eemsdelta",
      "disabled": false,
      "locationSelectionAllowed": true
  }
]

