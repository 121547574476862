<template>
  <div class="TagConfigPanel DetailGrid">
    <div class="DetailGrid__nav">
      <h2 class="h3 TagConfigPanel__header">
        <span>
          Aannemers Beheer
        </span>

        <b-button
          variant="dark"
          type="submit"
          :disabled="disabled"
          @click="handleSubmit"
        >
          Opslaan
        </b-button>
      </h2>

      <Form
        ref="form"
        @error="handleValidationError"
        @submit="handleSubmit"
      >
        <Feedback
          :feedback="statusFeedback"
          :dismissible="true"
        />

        <Tags
          label="Voeg een aannemer toe"
          path="Contractors"
          :counters="['realisationCount']"
          :disabled="disabled"
          :values="config"
        />
      </Form>
    </div>
    <div class="DetailGrid__sidebar">
      <h4>Over aannemers</h4>

      <p>
        De checkbox 'Actief' bepaald of de aannemer wel of niet voor gebruikers beschikbaar
        is om toe te voegen aan realisatieprocessen.
      </p>
      <p>
        Met de prullenbak icoon kun je aannemers weggooien, dit kan alleen als de aannemer niet
        langer gekoppeld is realisatieprocessen.
      </p>
    </div>
  </div>
</template>

<script>
import dot from 'dot-object'

import { mapGetters } from 'vuex'

import Form from '@/components/form/Form'
import Feedback from '@/components/form/Feedback'
import Tags from '@/components/definitions/config/Tags.vue'

export default {
  name: 'ContractorConfigPanel',
  components: { Form, Tags, Feedback },
  data() {
    return {
      feedback: null,
      feedbackMessage: '',
      disabled: false,
      realisationListStorage: null,
      tableRecords: ['realisationTableRecords', 'getProcessTableRecords']
    }
  },
  computed: {
    ...mapGetters('tenant', ['getContractors', 'getExistingContractors']),
    ...mapGetters('realisations', {
      realisationRecords: 'records',
      realisationTableRecords: 'getRealisationTableRecords'
    }),
    ...mapGetters('processes', ['getProcessTableRecords']),
    
    statusFeedback() {
      if (this.feedback === null) {
        return {
          show: false,
        }
      }
      return {
        show: true,
        variant: this.feedback,
        message: this.feedbackMessage,
      }
    },
    config() {
      /**
       * Count records by contractor uuid
       */
      return {
        Contractors: this.getContractors.map(contractor => {
          contractor.realisationCount = this.countRecordsByContractors(contractor).length
          return contractor
        }),
      }
    },
  },
  methods: {
    countRecordsByContractors (contractor) {
      return this.realisationRecords
        .filter(record => record.values.Location.Contractor === contractor.uuid)
    },
    /**
     * Handle Validation Error
     */
    handleValidationError() {
      this.feedback = 'danger'
      this.feedbackMessage = 'Controleer of alle velden (juist) zijn ingevuld.'
    },
    /**
     * Handle realisastion list tags, filter options and localStorage for filter dropdowns (e.g contractors, tags)
     */
    resetProcessListFilters ({ contractors, tableRecords }) {
      const existingContractors = JSON.parse(JSON.stringify(this.getExistingContractors))
      existingContractors.map(contractor => {
        // If contractor label has changed
        const changedContractor = contractors.find(t => t.uuid === contractor.uuid && t.label !== contractor.label)
        if (changedContractor) {
          contractor.changedLabel = changedContractor.label
        }
      })

      this.realisationListStorage = JSON.parse(localStorage.getItem('realisationList'))
      const storageContractorFilter = this.realisationListStorage.filters?.['contractor.value']
      // If contractor label has changed, reset realisationList record contractor labels
      this[tableRecords].map(rec => {
        const contractorValue = rec.contractor?.value
        existingContractors.map(contractor => {
          if (storageContractorFilter && contractorValue === contractor.label && contractor.changedLabel) {
            rec.contractor.value = contractor.changedLabel
            // Reset realisation list storage if it has active contractor filter based on previous contractor label
            const [storageActiveContractorFilter] = this.realisationListStorage.filters['contractor.value'].constraints
            if (storageActiveContractorFilter?.value) {
              const contractorIndex = storageActiveContractorFilter.value?.findIndex(value => value.includes(contractor.label))

              if (contractorIndex !== -1) {
                this.realisationListStorage.filters['contractor.value'].constraints[0].value.splice(contractorIndex, 1, contractor.changedLabel)
              }
            }
          }
        })
      })
      // Reset storage active tag column dropdown filter options if tag label has changed
      const storageContractors = this.realisationListStorage.activeColumns.find(col => col.field === 'contractor')
      if (storageContractors) {
        storageContractors.options = existingContractors.map(contractor => contractor.changedLabel || contractor.label)
      }

      const storeType = tableRecords === 'realisationTableRecords'
          ? 'realisations/resetRealisationTableRecords'
          : 'processes/resetProcessTableRecords'

      this.$store.dispatch(storeType, { tableRecords: this[tableRecords] })
      localStorage.setItem('realisationList', JSON.stringify(this.realisationListStorage))
    },

    /**
     * Handle Submit event
     */
    async handleSubmit () {
      try {
        // console.log("submit")

        this.feedback = null
        this.disabled = true

        // Get form input
        const form = this.$refs['form']
        const values = form.getValues()
        let data = dot.object(values)

        // console.log(data)
        // TODO: Proper validation

        // Map the data
        data.Contractors = data.Contractors
          .filter(contractor => contractor !== null)
          .map(contractor => {
            return {
              uuid: contractor.uuid || false,
              label: contractor.label,
              enabled: !! (contractor.enabled.length && contractor.enabled.includes(true)),
              deleted_at: contractor.deleted_at || null,
              process: true,
            }
          })

        // Basic validation
        if (data.Contractors.some(contractor => contractor.label === '')) {
          this.feedbackMessage = 'Lege aannemers zijn niet toegestaan'
          this.feedback = 'danger'
          this.disabled = false
          return
        }
        if (data.Contractors.some(contractor => contractor.label.length > 60)) {
          this.feedbackMessage = 'Beperk aannemers tot maximaal 60 tekens'
          this.feedback = 'danger'
          this.disabled = false
          return
        }

        // Make the call
        const token = await this.$auth.getTokenSilently()
        const api = await fetch('/api/config_savecontractors', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data,
          }),
        })
        if (! api.ok) {
          this.feedbackMessage = 'Er ging iets mis met het opslaan. Probeer het later nog eens'
          this.feedback = 'danger'
          throw new Error(api.error)
        }

        const response = await api.json()

        this.tableRecords.forEach(tableRecords => {
          this.resetProcessListFilters({ contractors: response.data.contractors, tableRecords })
        })

        // Update the tenant config in the Vue store
        this.$store.dispatch('tenant/setConfig', { config: response.data })

        this.feedbackMessage = 'De wijzigingen zijn opgeslagen'
        this.feedback = 'success'
        this.disabled = false

      } catch (e) {
        this.disabled = false
        console.log(e)
      }
    },

  },
}
</script>

<style lang="scss">
.TagConfigPanel {

  &__header {
    display: flex;
    justify-content: space-between;
  }
}
</style>
