<template>
  <div v-if="reference" :class="type === RECORD_TYPE.ASSETS ? '' : 'TheComments'">
    <div v-if="commentsVisible || type === RECORD_TYPE.ASSETS"
      :class="type === RECORD_TYPE.ASSETS ? '' : 'TC-Container'">
      <header class="TC-Container__header">
        <span v-if="commentCounter" class="mr-1">{{ commentCounter }}</span>
        <span>{{ commentCounter === 1 ? 'Locatie Opmerking' : 'Locatie Opmerkingen' }}</span>
      </header>
      <form class="TC-Container__input" @submit.stop.prevent="handleSaveNew">
        <b-alert class="w-100" :show="error !== false" variant="danger">
          {{ error }}
        </b-alert>
        <b-form-textarea id="commentTextArea" v-model="newComment" size="sm" placeholder="Plaats een opmerking"
          :disabled="disabled || editingId !== null" rows="1" max-rows="6" />
        <b-button type="submit" size="sm" variant="dark" :disabled="disabled || editingId !== null">
          Plaats opmerking
        </b-button>
      </form>
      <section class="TC-Container__body" :class="{ 'TC-Container__body--no-overflow': type === RECORD_TYPE.ASSETS }">
        <ul>
          <li v-for="comment in comments" :key="comment.id" class="TC-Comment">
            <div class="TC-Comment__meta d-flex justify-content-between">
              <div>
                <strong>{{ comment.name }}</strong> <br>
                <span v-if="isRealisationComment && comment.activeStep" class="TC-Comment__step">
                  Geplaatst bij locatie {{ comment.activeStep }}
                </span>
              </div>
              <span class="TC-Comment__date">{{ comment.date }}</span>
            </div>
            <p v-if="editingId === comment.id" class="TC-Comment__body">
              <b-form-textarea id="commentTextArea" v-model="editComment" size="sm" :disabled="disabled" rows="1"
                max-rows="6" />
            </p>

            <p v-else class="TC-Comment__body">
              {{ comment.body }}
            </p>

            <div v-if="editingId || comment.canEdit || comment.isAllowedToRemove || canChangeCommentStatus"
              class="TC-Comment__edit d-flex justify-content-end align-items-center mt-2">
              <CommentStatus v-if="type !== RECORD_TYPE.ASSETS" :status="comment.status"
                @update-status="value => handleUpdateStatus({ id: comment.id, body: comment.body, status: value })" />

              <div class="d-flex justify-content-end flex-grow-1">
                <div v-if="editingId === comment.id" class="mt-9">
                  <b-button size="sm mr-2" variant="outline-danger" :disabled="disabled" @click="handleCancelEditing">
                    Annuleren
                  </b-button>
                  <b-button size="sm" variant="dark" :disabled="disabled" @click="handleSaveEdit">
                    Opslaan
                  </b-button>
                </div>
                <div v-else class="d-flex">
                  <span v-if="comment.isAllowedToRemove" class="d-flex align-items-center u-clickable u-underline mr-2"
                    :class="{ 'u-disabled': disabled }" @click="handleDelete({ id: comment.id })">
                    <b-icon icon="trash" class="mr-1" />
                    <strong>Verwijderen</strong>
                  </span>
                  <span v-if="comment.canEdit" class="d-flex align-items-center u-clickable u-underline"
                    @click="handleStartEditing({ id: comment.id, body: comment.body })">
                    <b-icon icon="pencil-square" class="mr-1" />
                    <strong>Bewerken</strong>
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </div>
    <button v-if="type !== RECORD_TYPE.ASSETS" class="TheComments__button" @click="toggleComments">
      <b-icon v-if="!commentsVisible" icon="chat-left-text-fill" />
      <b-icon v-else class="h2" icon="x" />
      <b-badge v-if="commentCounter" :variant="commentIndicatorVariant">
        {{ commentCounter }}
      </b-badge>
    </button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import CommentStatus from '@/components/CommentStatus'
import { multilineUnicodeString, getInvalidCharacters } from '@/services/validation'
import { checkStatus } from '@/helpers/api'

import { RECORD_TYPE, RECORD_TYPES } from '@/../shared/valueholders/recordTypes'
import { COMMENT_STATUSES, COMMENT_STATUS } from '@/../shared/valueholders/commentStatuses'

export default {
  name: 'Comments',
  components: { CommentStatus },
  props: {
    record: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: () => RECORD_TYPE.PROCESS,
      validator: value => RECORD_TYPES.includes(value),
    },
  },
  data() {
    return {
      recordData: this.record,
      commentsVisible: false,
      newComment: null,
      disabled: false,
      error: false,
      editingId: null,
      editComment: '',
      RECORD_TYPE
    }
  },
  computed: {
    ...mapGetters('user', [
      'canChangeCommentStatus',
      'canModerateComments',
    ]),
    reference() {
      return this.recordData.ref
    },
    Location() {
      return this.recordData.values?.Location || {}
    },
    currentVersion() {
      if (this.type === RECORD_TYPE.REQUEST) {
        return 1
      }

      return this.Location.version || 1
    },
    currentUser() {
      return this.$auth.user
    },
    currentUserId() {
      return this.currentUser.sub
    },
    commentCounter() {
      return this.comments.length || 0
    },
    comments() {
      // console.log(this.currentUser)
      // console.log(this.Location)
      return (this.recordData.comments || [])
        .filter(comment => comment.deleted_at === false)
        .sort((a, b) => {
          return b.Time.localeCompare(a.Time)
        })
        .map((comment) => {
          return {
            id: comment.id,
            userId: comment.UserId,
            name: comment.UserName,
            date: this.toLocalDate({ time: comment.Time }),
            body: comment.Message,
            activeStep: comment.Version,
            canEdit: comment.UserId === this.currentUserId || this.canModerateComments,
            isAllowedToRemove: comment.UserId === this.currentUserId || this.canModerateComments,
            status: comment.status,
          }
        })
    },
    isRealisationComment() {
      return this.type === RECORD_TYPE.PROCESS
    },
    commentIndicatorVariant() {
      let todo = false

      // Check whether there are any comments that haven't been dealt with
      if (this.comments.some(comment => {
        // In passing, also check for TODO status
        if (comment.status === COMMENT_STATUS.TODO) {
          todo = true
          return false
        }

        return !COMMENT_STATUSES.includes(comment.status)
      })) {
        // At least one comment has to be dealt with
        return 'danger'
      }

      // At least one comment is marked as TODO
      if (todo) {
        return 'warning'
      }

      // Nothing of interest
      return 'lightgray'
    },
  },
  methods: {
    ...mapMutations('realisations', [
      'updateRecordComments',
    ]),
    ...mapMutations('requests', [
      'updateRequestComments'
    ]),
    toggleComments(e) {
      e.preventDefault()
      // console.log("%cclicked", "font-weight:bold;font-size:2rem");
      this.commentsVisible = !this.commentsVisible
    },
    toLocalDate({ time }) {
      let format = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }
      let [y, m, d, hh, mm, ss, ms] = time.match(/\d+/g)
      let date = new Date(Date.UTC(y, m - 1, d, hh, mm, ss, ms))
      return date.toLocaleString([], format)
    },
    handleUpdateStatus: async function ({ id, body, status }) {
      if (!this.canChangeCommentStatus) {
        return
      }

      try {
        if (!status || (!COMMENT_STATUSES.includes(status))) {
          this.error = 'Uw geselecteerde status is niet herkend.'
          return
        }

        const payload = this.getPayload({
          action: 'edit',
          message: body,
          id,
          status,
        })

        const token = await this.$auth.getTokenSilently()
        const api = await fetch('/api/comment', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        })
          .then(await checkStatus)

        if (api.ok) {
          const response = await api.json()
          this.updateCommentsFromResponse({ response })

          this.error = false
        }
      } catch (e) {
        this.error = 'De status kon niet worden aangepast.'
        this.disabled = false
      }
    },
    handleStartEditing({ id, body }) {
      this.handleCancelEditing()
      this.editComment = body
      this.editingId = id
    },
    handleCancelEditing() {
      this.editingId = null
      this.editComment = ''
    },
    handleDelete: async function ({ id }) {
      if (!this.canChangeCommentStatus) {
        return
      }

      try {
        const payload = this.getPayload({
          action: 'delete',
          id,
        })

        const token = await this.$auth.getTokenSilently()
        const api = await fetch('/api/comment', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        })
          .then(await checkStatus)

        if (api.ok) {
          const response = await api.json()
          this.updateCommentsFromResponse({ response })

          this.error = false
        }
      } catch (e) {
        this.error = 'Het bericht kon niet worden verwijderd.'
        this.disabled = false
      }
    },
    handleSaveEdit: async function () {
      try {
        // Don't even bother
        if (this.editComment.trim() === '') {
          return
        }

        this.disabled = true
        this.error = false

        if (!multilineUnicodeString(this.editComment)) {
          this.error = `Uw invoer bevat karakters die uit veiligheidsoverweging worden geweigerd: ${getInvalidCharacters(this.editComment)}`
          this.disabled = false
          return
        }


        const payload = this.getPayload({
          id: this.editingId,
          action: 'edit',
          message: this.editComment,
          status: this.comments.find(comment => comment.id === this.editingId)?.status,
        })

        const token = await this.$auth.getTokenSilently()
        const api = await fetch('/api/comment', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        })
          .then(await checkStatus)

        if (api.ok) {
          const response = await api.json()

          this.updateCommentsFromResponse({ response })

          this.editingId = null
          this.editComment = ''
        }

        this.disabled = false
      } catch (e) {
        this.error = 'De opmerking kon niet worden opgeslagen.'
        this.disabled = false
      }
    },
    handleSaveNew: async function () {
      try {
        // Don't even bother
        if (this.newComment?.trim() === '' || this.newComment === null) {
          this.error = 'De opmerking mag niet leeg zijn.'
          return
        }

        this.disabled = true
        this.error = false

        if (!multilineUnicodeString(this.newComment)) {
          this.error = `Uw invoer bevat karakters die uit veiligheidsoverweging worden geweigerd: ${getInvalidCharacters(this.newComment)}`
          this.disabled = false
          return
        }


        const payload = this.getPayload({
          id: 0,
          action: 'create',
          message: this.newComment,
          status: null,
        })

        const token = await this.$auth.getTokenSilently()
        const api = await fetch('/api/comment', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        })
          .then(await checkStatus)

        if (api.ok) {
          const response = await api.json()

          this.updateCommentsFromResponse({ response })

          this.newComment = ''
        }

        this.disabled = false
      } catch (e) {
        this.error = 'De opmerking kon niet worden opgeslagen.'
        this.disabled = false
      }
    },
    updateCommentsFromResponse({ response }) {
      if (this.type === RECORD_TYPE.PROCESS) {
        this.updateRecordComments({
          ref: this.reference,
          comments: response.data.record.data.comments,
        })
      }

      if (this.type === RECORD_TYPE.REQUEST) {
        this.updateRequestComments({
          ref: this.reference,
          comments: response.data.record.data.comments
        })
      }

      if (this.type === RECORD_TYPE.ASSETS) {
        if (!this.recordData.comments) {
          Vue.set(this.recordData, 'comments', [])
        }
        this.recordData.comments = response.data.record.data.comments;
      }
    },
    getPayload({ id, action, message, status }) {
      return {
        type: this.type,
        ref: this.reference,
        data: {
          version: this.currentVersion,
          Message: message || '',
          number: id,
          status: status || '',
          action,
        },
      }
    },
  },
}
</script>

<style lang="scss">
//GENERAL COMPONENT LAYOUT

.TheComments {
  //Positioning of this block itself
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  justify-self: right;
  align-self: end;

  //Positioning of elements inside this block
  $gap-size: 20px;
  position: fixed;
  max-height: calc(100% - 65px);
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
  gap: $gap-size;
  justify-items: right;
  align-items: end;

  padding-bottom: 30px;
  padding-right: 30px;
  padding-top: $gap-size;
  padding-left: 30px;

  bottom: 0;
  right: 0;
  text-align: left;

  &__button {
    position: relative;
    grid-row: 2 / span 1;

    border: none;
    $tc-button-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $tc-button-size;
    height: $tc-button-size;
    background-color: $primary;
    color: white;
    border-radius: $tc-button-size;
    transition-property: background-color;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    cursor: pointer;

    svg {
      margin: 0;
    }

    &:hover {
      background-color: darken($primary, 10);
    }

    .badge {
      user-select: none;
      position: absolute;
      top: -3px;
      right: -3px;
      border-radius: 50%;
      padding-bottom: .3rem;
    }
  }
}

//NAMESPACED COMMENT BLOCK LAYOUT
.TC-Container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
  max-width: 33vw;
  // max-height: calc(100% - 65px);
  width: 560px;
  min-width: 400px;
  height: 100%;
  overflow: hidden;

  border: 1px solid $grey-1;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);

  &__body {
    overflow-y: scroll;

    &--no-overflow {
      overflow-y: hidden;
    }

    ul {
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
    }
  }

  &__header,
  &__input {
    padding: $padding-sm;
    border-bottom: 1px solid $grey-1;
  }

  &__header {
    font-weight: 500;
  }

  &__input {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    justify-items: right;
    gap: 10px;
  }
}

//NAMESPACED COMMENT LAYOUT
.TC-Comment {
  padding: $padding-sm;
  border-bottom: 1px solid $grey-1;

  p {
    margin-bottom: 0;
  }

  &:last-child {
    border: none;
  }

  &__meta {
    padding-bottom: $padding-sm;
  }

  &__date,
  &__step {
    color: $grey-2;
    font-size: 0.82rem;
  }

  &__edit {
    font-size: 0.82rem;
  }
}
</style>
