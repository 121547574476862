import Process from '@/models/Process'
import ProcessesConfig from '@/components/Table/configs/ProcessesConfig';
import { realisationListFields } from '@/config';

const state = {
  selectedProcess: null,
  selectedAsset: null,
  processTableRecords: [],
  currentProcessType: null,
  counts: {
    total: 0,
    current: 0
  },
}

const getters = {
  getSelectedProcess: (state) => state.selectedProcess,
  getSelectedAsset: (state) => state.selectedAsset,
  getProcessTableRecords: (state) => state.processTableRecords,
  getCurrentProcessType: (state) => state.currentProcessType,
  getCounts: state => state.counts,
}
const actions = {
  setSelectedAsset ({ commit }, { asset }) {
    commit('setSelectedAsset', { asset })
  },

  setCurrentProcessType ({ commit }, { processType }) {
    commit('setCurrentProcessType', { processType })
  },

  resetProcessTableRecords ({ commit }, { tableRecords }) {
    commit('resetProcessTableRecords', { tableRecords })
  },

  setSelectedProcess ({ commit }, { process }) {
    commit('setSelectedProcess', { process })
  },

  setProcessTableRecords ({ commit }, { records, processType }) {
    commit('setProcessTableRecords', { records, processType })
  },

  replaceProcess ({ commit }, { process }) {
    commit('replaceProcess', { process })
  },

  deleteProcess ({ commit }, { uuid }) {
    commit('deleteProcess', { uuid })
  },

  setCounts ({ commit }, { counts }) {
    commit('setCounts', { counts })
  }
}
const mutations = {
  resetProcessTableRecords (state, { tableRecords }) {
    state.processTableRecords = tableRecords
  },

  setSelectedProcess (state, { process }) {
    state.selectedProcess = process
  },

  setSelectedAsset (state, { asset }) {
    state.selectedAsset = asset
  },

  setProcessTableRecords (state, { records, processType }) {
    state.processTableRecords = records
    state.currentProcessType = processType
  },

  setCurrentProcessType (state, { processType }) {
    state.currentProcessType = processType
  },

  replaceProcess (state, { process }) {
    if (!state.processTableRecords.length) return

    const mappedRecord = ProcessesConfig.dataMapper(new Process({ data: process.data, ref: process.ref['@ref'].id }))
    const processIndex = state.processTableRecords.findIndex(current => current.uuid.value === mappedRecord?.uuid)

    if (processIndex !== -1) {
      state.processTableRecords.splice(processIndex, 1, realisationListFields({ record: mappedRecord }))
    } else {
      // Created process
      state.processTableRecords.push(realisationListFields({ record: mappedRecord }))
    }
  },

  deleteProcess (state, { uuid }) {
    const processIndex = state.processTableRecords.findIndex(current => current.uuid.value === uuid)
    if (processIndex !== -1) {
      state.processTableRecords.splice(processIndex, 1)
    }
  },

  setCounts (state, { counts }) {
    state.counts = counts
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
