<template>
  <span v-if="fileRecordFailedToLoad" class="mb-4 mr-2">Bestandsinformatie kon niet worden opgehaald</span>
  <span v-else-if="!isThisFileAvailable" class="mb-4 mr-2">Bestandsinformatie wordt opgehaald...</span>
  <span v-else-if="!isAvailableForDownload" class="mb-4 mr-2">{{ file.getFullFilename() }} (Onbeschikbaar)</span>
  <a v-else class="mb-4 mr-2" @click="handleDownload">{{ file.getFullFilename() }}</a>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { saveAs } from 'file-saver';

/**
 * List of UUID based download links
 *  TODO: Offer retry btn on failure to get File data
 */
export default {
  name: 'FileUuidDownloadLink',
  props: {
    uuid: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters('files', ['isFileAvailable', 'getFileByUuid', 'getFileFailedToLoad']),
    ...mapGetters('tenant', ['getTenant']),
    /**
     * @return {boolean}
     */
    isThisFileAvailable() {
      return this.isFileAvailable({
        uuid: this.uuid
      });
    },
    /**
     *
     */
    file() {
      if (!this.isThisFileAvailable) {
        return null;
      }
      return this.getFileByUuid({ uuid: this.uuid });
    },
    /**
     * @return {boolean}
     */
    isAvailableForDownload() {
      if (!this.isThisFileAvailable) {
        return null;
      }
      return this.file.isAvailableForDownload();
    },
    /**
     * @returns {boolean}
     */
    fileRecordFailedToLoad() {
      return this.getFileFailedToLoad({
        uuid: this.uuid
      });
    }
  },
  async created() {
    console.log('Created', !this.isThisFileAvailable);
    if (!this.isThisFileAvailable) {
      await this.loadFileByUuid({
        uuid: this.uuid
      });
    }
  },
  methods: {
    ...mapActions('files', ['loadFileByUuid']),

    /**
     * The uuid based file download
     */
    handleDownload: async function () {
      try {
        const token = await this.$auth.getTokenSilently();
        const api = await fetch(
          `${process.env.VUE_APP_CBAPI_BASE_URL}/v1/${this.getTenant.slug}/files/${this.file.uuid}/download-link`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-API-KEY': process.env.VUE_APP_CBAPI_API_KEY
            }
          }
        );

        if (!api.ok) {
          throw new Error('server error');
        }

        const response = await api.json();
        if (!response.link) {
          throw new Error('missing url');
        }
        // Chrome, FF, Edge
        if (window.browser && window.browser.downloads) {
          window.browser.downloads.download({
            url: response.link
          });
        } else {
          // Safari, etc.
          const res = await fetch(response.link);
          const blob = await res.blob();
          saveAs(blob, `${this.file.filename}.${this.file.extension}`);
        }
      } catch (e) {
        // console.log(e)
        this.$bvModal.show('file-error');
      }
    }
  }
};
</script>
