import store from '@/store';

const config = {
  defaultRealisationTableFields: () => {
    const getCharchingStationVersions = store.getters['tenant/getCharchingStationVersions'];

    return [
      { field: 'reference', label: 'Referentie', width: 192 },
      { field: 'street', label: 'Straat', width: 300 },
      { field: 'houseNumber', label: 'Huisnummer', width: 140, filterDisabled: true },
      { field: 'houseNumberSuffix', label: 'Toevoeging', width: 140, filterDisabled: true },
      { field: 'postalCode', label: 'Postcode', width: 160 },
      { field: 'city', label: 'Plaats', width: 280, filterDisabled: true },
      { field: 'address', label: 'Adres', width: 500 },
      //{ field: 'country', label: 'Land', width: 150 },
      {
        field: 'municipalityName',
        label: 'Gemeente',
        width: 285,
        fn: 'setMunicipalityOptions',
        matchMode: 'filterByArrayEquals'
      },
      { field: 'lat', label: 'Latitude', width: 192, filterDisabled: true },
      { field: 'lng', label: 'Longitude', width: 192, filterDisabled: true },
      { field: 'step', label: 'Stap', width: 300, fn: 'setStepLabels', matchMode: 'filterByArrayEquals' },
      { field: 'progress_days', label: 'In deze stap', width: 192, filterDisabled: true },
      {
        field: 'chargingpointStatus',
        label: 'Laadpaal status',
        width: 192,
        fn: 'setChargingpointStatuses',
        matchMode: 'filterByArrayEquals'
      },
      {
        field: 'currentStatus',
        label: 'Status',
        width: 132,
        fn: 'setStatusOptions',
        matchMode: 'filterByArrayEquals',
        filterDisabled: true
      },
      {
        field: 'currentStatusMessage',
        label: 'Onhold & Annulerings reden',
        width: 544,
        filterDisabled: true
      },
      { field: 'parkinglotIds', label: 'Parkeerplaatsen', width: 260, filterDisabled: true },
      { field: 'requestCount', label: 'Aanvragen', width: 192 },
      {
        field: 'category',
        label: 'Type',
        width: 192,
        fn: 'setCategoryOptions',
        matchMode: 'filterByArrayEquals'
      },
      {
        field: 'numberOfChargingStations',
        label: 'Aantal laadpalen',
        width: 192,
        filterDisabled: true
      },
      {
        field: 'vkb',
        label: 'VKB Status',
        width: 192,
        fn: 'setVkbStatusOptions',
        matchMode: 'filterByArrayEquals'
      },
      {
        field: 'VKBPublicationDate',
        label: 'Datum VKB publicatie',
        width: 256,
        filterDisabled: true
      },
      { field: 'VKBDefinitiveDate', label: 'Datum VKB definitief', width: 236, filterDisabled: true },
      { field: 'gridOperatorNumber', label: 'Netbeheerdernummer', width: 244, filterDisabled: true },
      {
        field: 'connectionValue',
        label: 'Aansluitwaarde',
        width: 192,
        fn: 'setGridConnectionValues',
        matchMode: 'filterByArrayEquals',
        filterDisabled: !config.gridConnectionValues?.length
      },
      { field: 'EAN', label: 'EAN', width: 192, filterDisabled: true },
      { field: 'chargerID', label: "Laadpaal ID's", width: 192,  filterDisabled: true },
      {
        field: 'chargingStationVersion',
        label: 'Uitvoering laadpaal',
        width: 227,
        fn: 'setCharchingStationVersions',
        matchMode: 'filterByArrayEquals',
        filterDisabled: !getCharchingStationVersions.length
      },
      { field: 'powerType', label: 'Type power', width: 192, fn: 'setPowerTypeOptions', matchMode: 'filterByArrayEquals', filterDisabled: true },
      {
        field: 'contractor',
        label: 'Aannemer',
        width: 192,
        fn: 'setContractors',
        matchMode: 'filterByArrayEquals'
      },
      {
        field: 'realisationStartDate',
        label: 'Realisatie startdatum',
        width: 248,
        filterDisabled: true
      },
      {
        field: 'gridConnectionDate',
        label: 'Datum aanvraag netaansluiting',
        width: 317,
        filterDisabled: true
      },
      {
        field: 'realisation_date',
        label: 'Geplande realisatiedatum',
        width: 278,
        filterDisabled: true
      },
      { field: 'updated_at', label: 'Gewijzigd op', width: 192, filterDisabled: true },
      {
        field: 'dateOfCommissioning',
        label: 'Datum in bedrijf genomen',
        width: 280,
        filterDisabled: true
      },
      { field: 'tagcount', label: 'Aantal tags', width: 192, filterDisabled: true },
      {
        field: 'tags',
        label: 'Tags',
        width: 322,
        fn: 'setTagOptions',
        matchMode: 'commaSeparatedArrayOR'
      }
    ];
  }
};
export default config;
