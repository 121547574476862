<template>
  <div v-if="currentTour">
    <evtools-tour
      :steps="currentTour.steps"
      :begin="begin"
      :route="$route.name || ''"
      :tour-name="tourName"
      @start-tour="begin = true"
      @end="$emit('end-tour')"
    >
      <template
        v-if="showTourIntroduction"
        #introduction
      >
        <p>Graag informeren we je over de nieuwe veranderingen binnen <b>Workflow</b>.</p>
        <p>
          Feedback, suggesties en vragen zijn welkom op <MailtoSupport />.
        </p>
      </template>
    </evtools-tour>
  </div>
</template>

<script>
import { tour } from '@/config'
import MailtoSupport from '@/components/common/MailtoSupport.vue'

export default {
  name: 'Tour',
  components: {
    MailtoSupport,
  },
  props: {
    showTourIntroduction: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      begin: false,
      tour,
    }
  },
  computed: {
    currentTour () {
      const isGenericFeatureSeen = !this.tour?.allRoutes || localStorage.getItem(`allRoutes|${this.tour.date}`)
      const name = isGenericFeatureSeen ? this.$route.name : 'allRoutes'

      return this.tour[name]
    },
    tourName () {
      return `${this.currentTour?.name}|${this.tour.date}`
    },
    showTour(){
      return !this.showTourIntroduction
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.begin = !localStorage.getItem(this.tourName) && this.showTour
    })
  }
}
</script>
