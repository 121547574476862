<template>
  <div class="TagConfigPanel DetailGrid">
    <div class="DetailGrid__nav">
      <h2 class="h3 TagConfigPanel__header">
        <span>
          Tag Beheer
        </span>

        <b-button
          variant="dark"
          type="submit"
          :disabled="disabled"
          @click="handleSubmit"
        >
          Opslaan
        </b-button>
      </h2>

      <Form
        ref="form"
        @error="handleValidationError"
        @submit="handleSubmit"
      >
        <Feedback
          :feedback="statusFeedback"
          :dismissible="true"
        />

        <Tags
          :disabled="disabled"
          :values="config"
        />
      </Form>
    </div>
    <div class="DetailGrid__sidebar">
      <h4>Over tags</h4>

      <p>
        De checkbox 'Actief' bepaald of de tag wel of niet voor gebruikers beschikbaar
        is om toe te voegen aan realisatieprocessen en aanvragen.
      </p>
      <p>
        Met de prullenbak icoon kun je tags weggooien, dit kan alleen als de tag niet
        langer gekoppeld is aan aanvragen en realisatieprocessen.
      </p>
    </div>
  </div>
</template>

<script>
import dot from 'dot-object'

import { mapGetters } from 'vuex'

import Form from '@/components/form/Form'
import Feedback from '@/components/form/Feedback'
import Tags from '@/components/definitions/config/Tags.vue'

export default {
  name: 'TagConfigPanel',
  components: { Form, Tags, Feedback },
  data() {
    return {
      feedback: null,
      feedbackMessage: '',
      disabled: false,
      realisationListStorage: null,
      tableRecords: ['realisationTableRecords', 'getProcessTableRecords']
    }
  },
  computed: {
    ...mapGetters('tenant', ['getTags', 'getExistingTags']),
    ...mapGetters('realisations', {
      realisationRecords: 'records',
      realisationTableRecords: 'getRealisationTableRecords'
    }),
    ...mapGetters('requests', { requestRecords: 'requests'}),
    ...mapGetters('processes', ['getProcessTableRecords']),

    statusFeedback() {
      if (this.feedback === null) {
        return {
          show: false,
        }
      }
      return {
        show: true,
        variant: this.feedback,
        message: this.feedbackMessage,
      }
    },
    config() {
      // console.log("eval")

      /**
       * Count records by tag uuid
       */
      const countRecordsByTags = (counts, record) => {
        (record.Tags || []).forEach(uuid => {
          counts[uuid] = counts[uuid] ? (counts[uuid] + 1) : 1
        })
        return counts
      }

      let realisationTagCounts = this.realisationRecords
        .reduce(countRecordsByTags, {})
      let requestTagCounts = this.requestRecords
        .reduce(countRecordsByTags, {})

      return {
        Tags: this.getTags.map(tag => {
          tag.realisationCount = realisationTagCounts[tag.uuid] || 0
          tag.requestCount = requestTagCounts[tag.uuid] || 0
          return tag
        }),
      }
    },
  },
  methods: {
    /**
     * Handle Validation Error
     */
    handleValidationError() {
      this.feedback = 'danger'
      this.feedbackMessage = 'Controleer of alle velden (juist) zijn ingevuld.'
    },
    /**
     * Handle realisastion list tags, filter options and localStorage for filter dropdowns (e.g contractors, tags)
     */
    resetProcessListFilters ({ tags, tableRecords }) {
      const existingTags = JSON.parse(JSON.stringify(this.getExistingTags))
      existingTags.map(tag => {
        // If tag label is changed
        const changedTag = tags.find(t => t.uuid === tag.uuid && t.label !== tag.label)
        if (changedTag) {
          tag.changedLabel = changedTag.label
        }
      })

      this.realisationListStorage = JSON.parse(localStorage.getItem('realisationList'))
      const storageTagsFilter = this.realisationListStorage.filters?.['tags.value']
      // If tag label has changed, reset realisationList record tag labels
      this[tableRecords].map(rec => {
        const tagValues = rec.tags.value.split(', ')
        
        existingTags.map(tag => {
          if (storageTagsFilter && tagValues.includes(tag.label) && tag.changedLabel) {
            rec.tags.value = rec.tags.value.replace(tag.label, tag.changedLabel)
            // Reset realisation list storage if it has active tag filter based on previous tag label
            const [storageActiveTagFilter] = storageTagsFilter.constraints
            if (storageActiveTagFilter?.value) {
              const tagIndex = storageActiveTagFilter.value?.findIndex(value => value.includes(tag.label))
              
              if (tagIndex !== -1) {
                this.realisationListStorage.filters['tags.value'].constraints[0].value.splice(tagIndex, 1, tag.changedLabel)
              }
            }
          }
        })
      })

      // Reset storage active tag column dropdown filter options if tag label has changed
      const storageTags = this.realisationListStorage.activeColumns.find(col => col.field === 'tags')
      storageTags.options = existingTags.map(tag => tag.changedLabel || tag.label)

      const storeType = tableRecords === 'realisationTableRecords'
          ? 'realisations/resetRealisationTableRecords'
          : 'processes/resetProcessTableRecords'

      this.$store.dispatch(storeType, { tableRecords: this[tableRecords] })
      localStorage.setItem('realisationList', JSON.stringify(this.realisationListStorage))
    },

    /**
     * Handle Submit event
     */
    handleSubmit: async function() {
      try {
        // console.log("submit")

        this.feedback = null
        this.disabled = true

        // Get form input
        const form = this.$refs['form']
        const values = form.getValues()
        let data = dot.object(values)

        // console.log(data)
        // TODO: Proper validation

        // Map the data
        data.Tags = data.Tags
          .filter(tag => tag !== null)
          .map(tag => {
            // console.log(tag)
            return {
              uuid: tag.uuid || false,
              label: tag.label,
              enabled: !! (tag.enabled.length && tag.enabled.includes(true)),
              deleted_at: tag.deleted_at || null,
              request: true, // tag.request.length && tag.request[0] === true,
              process: true, // tag.process.length && tag.process[0] === true,
            }
          })

        // Basic validation
        if (data.Tags.some(tag => tag.label === '')) {
          this.feedbackMessage = 'Lege tags zijn niet toegestaan'
          this.feedback = 'danger'
          this.disabled = false
          return
        }
        if (data.Tags.some(tag => tag.label.length > 60)) {
          this.feedbackMessage = 'Beperk tags tot maximaal 60 tekens'
          this.feedback = 'danger'
          this.disabled = false
          return
        }
        

        // Make the call
        const token = await this.$auth.getTokenSilently()
        const api = await fetch('/api/config_savetags', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data,
          }),
        })
        if (! api.ok) {
          this.feedbackMessage = 'Er ging iets mis met het opslaan. Probeer het later nog eens'
          this.feedback = 'danger'
          throw new Error(api.error)
        }

        const response = await api.json()

        this.tableRecords.forEach(tableRecords => {
          this.resetProcessListFilters({ tags: response.data.tags, tableRecords })
        })

        // Update the tenant config in the Vue store
        this.$store.dispatch('tenant/setConfig', { config: response.data })

        this.feedbackMessage = 'De wijzigingen zijn opgeslagen'
        this.feedback = 'success'
        this.disabled = false

      } catch (e) {
        this.disabled = false

        // console.log(e)
      }
    },

  },
}
</script>

<style lang="scss">
.TagConfigPanel {

  &__header {
    display: flex;
    justify-content: space-between;
  }
}
</style>
