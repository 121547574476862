
import { formatPostalCode } from '@/helpers/string'

/**
 * A Location is part of Realisation record
 *  One Realisation record can hold multiple Locations
 */
class Location {
  constructor({ data, copy, processType }) {
    let model = {}

    // short circuit making copies
    if (copy || this.LooksAndQuacksLikeCopy({ data })) {
      model = this.makePlainCopy({ data })
    } else {

      model = this.commonStructure({ data, processType })

      /**
       * Tenant specific fields
       */
      model.ChargePointOperator = this.$store.getters['tenant/getTenant'].name
      model.GridOperator = data.GridOperator || ''
      model.Municipality = data.Municipality || null
      model.FinUnit = data.FinUnit || 'spv003'

      if (!model.PlannedRealisation.Permit) {
        model.PlannedRealisation.Permit = 'nvt'
      }
    }

    //
    Object.keys(model).forEach(key => {
      this[key] = model[key]
    })
  }
  LocationCopy({ version }) {
    let oldData = JSON.parse(JSON.stringify(this))

    let data = {
      Location: oldData.Location,
      Remarks: oldData.Remarks,
      ChargePointOperator: oldData.ChargePointOperator,
      GridOperator: oldData.GridOperator,
      ParkingSpots: oldData.ParkingSpots,
      Contractor: oldData.Contractor,
      CPOAdvice: {
        SumAdditionalCost: oldData.CPOAdvice.SumAdditionalCost || '',
        AdditionalCostDescription: oldData.CPOAdvice.AdditionalCostDescription || '',
      },
      LocationPreparation: {
        RequiresLocationPreparation: oldData.LocationPreparation.RequiresLocationPreparation || [],
        OtherReason: oldData.LocationPreparation.OtherReason || '',
      },
      Docs: {
        ParkingSpots: oldData.Docs.ParkingSpots || [],
        // Underground: oldData.Docs.Underground || [],
        CPOAdditional: oldData.Docs.CPOAdditional || [],
      },
      FinUnit: oldData.FinUnit,
      Municipality: oldData.Municipality,
    }

    data.version = version

    return new Location({ data, copy: true })
  }
  hasCoordinates() {
    return (this.Location.Latitude && this.Location.Longitude) || this.Location.chargingpointUuid
  }
  getCoordinates() {
    try {
      if (this.Location.chargingpointUuid) {
        let location = this.$store.getters['chargingpoints/getChargingPointByUUID']({
          uuid: this.Location.chargingpointUuid,
        })
        return location.data.address.coordinates
      }

      return {
        lat: this.Location.Latitude,
        lng: this.Location.Longitude,
      }
    } catch (e) {
      return false
    }
  }
  getAddress() {
    const location = this.Location
    const number = (`${location.HouseNumber || ''} ${location.HouseNumberSuffix || ''}`).trim()
    return (`${location.StreetName || ''} ${number}, ${formatPostalCode(location.PostalCode || '')} ${location.CityName || ''}`).trim().trim(',')
  }
  getStreetAndCity() {
    try {
      let location = this.Location
      let number = (`${location.HouseNumber || ''} ${location.HouseNumberSuffix || ''}`).trim()
      return (`${location.StreetName || ''} ${number}, ${location.CityName || ''}`).trim().trim(',')
    } catch (e) {
      return false
    }
  }
  hasChargingpointLocation() {
    return this.Location.chargingpointUuid
  }
  hasDocOfType({ doctype }) {
    return Array.isArray(this.Docs[doctype]) && this.Docs[doctype].length !== 0
  }
  getDocTypeCount({ doctype }) {
    return this.hasDocOfType({ doctype }) ? this.Docs[doctype].length : 0
  }
  LooksAndQuacksLikeCopy({ data }) {
    return data instanceof Location
  }
  commonStructure({ data, processType }) {

    /**
     * Address & coordinates
     */
    data.Location = data.Location || {}

    const countryMap = {
      'Netherlands': 'Nederland',
      'Belgium': 'België'
    }
    /**
     * Data model mapping
     */
    //console.log(processType, realisationData)
    let model = {
      version: data.version || 1,

      raw: JSON.parse(JSON.stringify(data)),

      /**
       * Address information & coordinates
       */
      Location: {
        chargingpointUuid: data.Location.chargingpointUuid || null,
        chargingpointStatus: data.Location.chargingpointStatus || null,
        StreetName: data.Location.StreetName || '',
        HouseNumber: data.Location.HouseNumber || null,
        HouseNumberSuffix: data.Location.HouseNumberSuffix || '',
        PostalCode: data.Location.PostalCode || '',
        CityName: data.Location.CityName || '',
        Province: data.Location.Province || '',
        Country: data.Location.Country ? (countryMap[data.Location.Country] || data.Location.Country) : '',
        Latitude: data.Location.Latitude || '',
        Longitude: data.Location.Longitude || '',
        AdditionalCoordinates: data.Location.AdditionalCoordinates || [],
      },

      /****************************************************************************
       * Basics
       */
      Remarks: data.Remarks || '',
      ChargePointOperator: data.ChargePointOperator || '',
      ChargingPointType: data.ChargingPointType || '',
      ContractorActivityType: data.ContractorActivityType || '',
      Contractor: data.Contractor || '',
      isGridConnectionChanged: data.isGridConnectionChanged || false,
      ChargingPointReplacementReasons: data.ChargingPointReplacementReasons || [],

      /****************************************************************************
       * Documents
       */
      Docs: data.Docs ? {
        LocationProposition: data.Docs.LocationProposition || [],
        StreetCabinet: data.Docs.StreetCabinet || [],
        ParkingSpots: data.Docs.ParkingSpots || [],
        Underground: data.Docs.Underground || [],
        Broker: data.Docs.Broker || [],
        GridOperator: data.Docs.GridOperator || [],
        CPOAdditional: data.Docs.CPOAdditional || [],
        TrafficDecision: data.Docs.TrafficDecision || [],
        DeliveryDocumentation: data.Docs.DeliveryDocumentation || [],
        SideView: data.Docs.SideView || [],
        TopView: data.Docs.TopView || [],
      } : {
        ParkingSpots: [],
        Underground: [],
        Broker: [],
        GridOperator: [],
        CPOAdditional: [],
        TrafficDecision: [],
        DeliveryDocumentation: [],
        SideView: [],
        TopView: [],
        StreetCabinet: []
      },

      /**
       * Mail log
       */
      MailLog: data.maillog || {},
    }


    /****************************************************************************
     * Parking spots
     */
    let ParkingSpotsType = 'station'
    if (data.ParkingSpots) {
      ParkingSpotsType = ['station', 'hub'].includes(data.ParkingSpots.Type)
        ? data.ParkingSpots.Type
        : 'station'
    }
    model.ParkingSpots = {
      Type: ParkingSpotsType,
      NumberOfChargingStations: data.ParkingSpots
        ? data.ParkingSpots.NumberOfChargingStations
        : 1,
      NumberOfParkingSpots: data.ParkingSpots
        ? data.ParkingSpots.NumberOfParkingSpots
        : 1,
      Identifiers: data.ParkingSpots && data.ParkingSpots.Identifiers
        ? data.ParkingSpots.Identifiers.map(value => {
          return {
            Identifier: value,
          }
        })
        : [],
    }


    /****************************************************************************
     * Broker Approval
     */
    model.BrokerApproval = data.BrokerApproval || {
      IsBrokerAccepted: '',
      BrokerName: '',
      BrokerComments: '',
      BrokerApprovalRequestedDate: '',
      BrokerApprovalReceivedDate: '',
    }
    // Only accept valid options
    if (data.BrokerApproval
      && ['yes', 'no', 'blanco', 'negative-advice', ''].includes(
        data.BrokerApproval.IsBrokerAccepted
      )) {
      model.BrokerApproval.IsBrokerAccepted = data.BrokerApproval.IsBrokerAccepted
    }


    /****************************************************************************
     * Grid Operator Approval
     */
    model.GridOperatorApproval = data.GridOperatorApproval || {
      IsGridOperatorAccepted: '',
      GridOperatorName: '',
      GridOperatorComments: '',
      GridOperatorApprovalRequestedDate: '',
      GridOperatorApprovalReceivedDate: '',
    }
    // Only accept valid options
    if (data.GridOperatorApproval
      && ['yes', 'no'].includes(data.GridOperatorApproval.IsGridOperatorAccepted)) {
      model.GridOperatorApproval.IsGridOperatorAccepted = data.GridOperatorApproval.IsGridOperatorAccepted
    }


    /****************************************************************************
     * CPO Advice
     */
    model.CPOAdvice = data.CPOAdvice || {
      CPOAdvice: null,
      CPOComments: '',
      SumAdditionalCost: null,
      Decision: null,
      AdditionalCostDescription: '',
      ChargingStationVersion: '',
      PowerType: 'AC'
    }


    /****************************************************************************
     * Order Confirmation
     */
    // model.OrderConfirmation = data.OrderConfirmation ? {
    //   IsOrderConfirmed: [data.OrderConfirmation.IsOrderConfirmed],
    //   IsAdditionalWorkConfirmed: [data.OrderConfirmation.IsAdditionalWorkConfirmed],
    //   Approver: data.OrderConfirmation.Approver,
    // } : {
    //   IsOrderConfirmed: [],
    //   IsAdditionalWorkConfirmed: [],
    //   Approver: '',
    // }
    /****************************************************************************
     * Various data from last steps (mostly dates)
     */
    model.TrafficDecision = data.TrafficDecision || {
      PublicationDate: '',
      DefinitiveDate: '',
    }

    model.GridConnection = data.GridConnection || {
      RequestDate: '',
      GridOperatorNumber: '',
      ConnectionValue: '',
      Removal: false,
      EAN: '',
      NewConnection: false
    }

    model.PlannedRealisation = data.PlannedRealisation || {
      PlannedDate: '',
      RemovalDate: ''
    }
    model.LocationPreparation = data.LocationPreparation || {
      RequiresLocationPreparation: [],
      OtherReason: '',
    }

    model.DateOfCommissioning = data.DateOfCommissioning || {
      DateOfCommissioning: '',
      DateOfEnergyConnection: '',
      EAN: '',
      DateOfRemoval: ''
    }
    const isRemovalProcess = processType?.value === 'removal'
    if (isRemovalProcess) {
      model.DateOfDecommissioning = data.DateOfDecommissioning || {
        DateOfDecommissioning: '',
        DateOfEnergyDisconnection: '',
        DateOfBackOfficeDisconnection: '',
        DateOfCoordinatesRemoval: ''
      }
    }

    model.FinalDelivery = data.FinalDelivery || {
      LocationReadyDate: '',
      ChargerReadyDate: '',
      BatchCode: '',
    }
    model.ChargerIds = data.ChargerIds ? data.ChargerIds.map(id => {
      return {
        Identifier: id,
      }
    }) : []

    model.ChargerIdsToReplace = data.ChargerIdsToReplace


    return model
  }
  makePlainCopy({ data }) {
    data = JSON.parse(JSON.stringify(data)) // data.raw


    // Only add raw if not yet set on original
    if (!data.raw) {
      data.raw = JSON.parse(JSON.stringify(data))
    }

    return data
  }
  getTrafficDecisionDetails() {
    return Object.assign({
      PublicationDate: false,
      DefinitiveDate: false,
      ConfirmedDate: false,
    }, this.TrafficDecision)
  }
}

export default Location
