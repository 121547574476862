
import { commonFilterWrapper } from '../utils'

export default {
  /**
   * Config reference
   * @type {string}
   */
  uuid: 'de3dae3b-1531-4d17-85f1-03a1605b3724',

  /**
   * Use to group table configurations
   *  Configs for realisation index should not be used for the statistics dashboard
   */
  category: 'requests',

  /**
   * Access requirements
   */
  access: {
    /**
     * Required access checks. Must pass all
     */
    requirements: [],

    // TODO: Maybe specicify exclude list? If you have one config, exclude this one
    supersededBy: [],
  },

  /**
   *
   */
  controlsComponentName: 'RequestControls',


  minWidth: '920px',
  maxWidth: '1900px',
  pageSize: 10,
  visualisation: 'table',

  /**
   * CSS Grid config
   *
   * @type {object}
   */
  layouts: {
    default: {
      id: 'default',
      label: 'Standaard',
      filters: {
        filters: ['reference', 'address', 'name', 'tags'],
        columns: '150px 2fr 240px 1fr auto',
        area: '"reference address name tags ."',
      },
      header: {
        properties: ['reference', 'address', 'name', 'tags', 'realisation_caseref', 'submitted_at', 'processed_at'],
        columns: '150px 4fr minmax(120px, 2fr) 1fr 2fr 130px 20px 130px auto',
        area: '"reference address name tags realisation_caseref submitted_at . processed_at ."',
      },
    },
    rejected: {
      id: 'rejected',
      label: 'Afgewezen',
      filters: {
        filters: ['reference', 'address', 'name', 'tags'],
        columns: '150px 2fr 240px 1fr auto',
        area: '"reference address name tags ."',
      },
      header: {
        properties: ['reference', 'address', 'name', 'tags', 'judgement_reason', 'submitted_at', 'processed_at'],
        columns: '150px 4fr minmax(120px, 2fr) 1fr 2fr 130px 20px 130px auto',
        area: '"reference address name tags judgement_reason submitted_at . processed_at ."',
      },
    },
  },

  headerLabels: {
    reference: 'Referentie',
    address: 'Adres',
    name: 'Naam',
    tags: 'Tags',
    submitted_at: 'Ingezonden',
    processed_at: 'Beoordeeld',
    judgement_reason: 'Reden',
    realisation_caseref: 'Realisatie',
  },
  cellComponents: {
    reference: null,
    address: null,
    name: null,
    tags: 'Tags',
    submitted_at: 'UpdatedAt',
    processed_at: 'UpdatedAt',
    realisation_caseref: 'RealisationCaseRef',
  },

  /**
   * sorting
   *  Note that a header may be sorted by a different field than itself
   *  An example is the referencenr used to sort the reference column numerically
   */
  sorting: {
    default: {
      type: 'number',
      sortBy: 'reference_nr',
    },
    reference: {
      type: 'number',
      sortBy: 'reference_nr',
    },
    address: {
      type: 'string',
      sortBy: 'address',
    },
    name: {
      type: 'string',
      sortBy: 'name',
    },
    tags: {
      type: 'number',
      sortBy: 'tagcount',
    },
    submitted_at: {
      type: 'number',
      sortBy: 'submitted_at_nr',
    },
    processed_at: {
      type: 'number',
      sortBy: 'processed_at_nr',
    },
  },

  /**
   * Take a record and convert it to the data we use for displaying / sorting / filtering records in this table
   * @type {Function}
   */
  dataMapper: (record) => {
    return {
      reference: record.case_ref,
      // used for sorting the reference column
      reference_nr: record.raw.case.counter,
      source: record.source,
      // object props
      address: record.summary.address,

      name: record.summary.name,

      tags: Array.isArray(record.Tags) ? record.Tags : [],
      tagcount: Array.isArray(record.Tags) ? record.Tags.length : 0,

      submitted_at: record.summary.submitted_at_short,
      submitted_at_nr: record.summary.submitted_at_nr,
      processed_at: record.summary.processed_at_short,
      processed_at_nr: record.summary.processed_at_nr,

      judgement_reason: record.judgement ? record.judgement.reasonLabel : '',
      realisation_caseref: record.uuid,


      // Turns row into a link
      route: {
        name: 'RequestDetail',
        params: {
          uuid: record.uuid,
        },
      },

      /**
       * Note: using dutch category names because these are also used as slugs
       */
      categories: {
        all: true,
        open: record.status === 2,
        accepted: record.status === 3,
        rejected: record.status === 4,
        removed: record.status === 5,
      },

      /**
       * Filter params
       */
      filteredOut: false,
      filterReason: null,
    }
  },


  /**************************************************************************
   * Filter configuration
   *
   * @type {object[]}
   */
  filters: {
    reference: {
      cost: 2,
      id: 'reference',
      label: 'Referentie',
      componentName: 'SingleStringFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value.toLowerCase(),
          callback: commonFilterWrapper({ callback: filter.callback }),
        }
      },
      callback: ({ record, value }) => record.reference.toLowerCase().includes(value),
    },

    address: {
      cost: 20,
      id: 'address',
      label: 'Adres',
      componentName: 'SingleStringFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value.toLowerCase(),
          callback: commonFilterWrapper({ callback: filter.callback }),
        }
      },
      callback: ({ record, value }) => record.address.toLowerCase().includes(value),
    },
    name: {
      cost: 20,
      id: 'name',
      label: 'Naam',
      componentName: 'SingleStringFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value.toLowerCase(),
          callback: commonFilterWrapper({ callback: filter.callback }),
        }
      },
      callback: ({ record, value }) => record.name.toLowerCase().includes(value),
    },

    tags: {
      cost: 50,
      id: 'tags',
      label: 'Tags',
      position: '',
      componentName: 'TagSelectFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          value: filter.filters.map(filter => filter.value),
          callback: commonFilterWrapper({ callback: filter.callback }),
        }
      },
      callback: ({ record, value }) => value.every(tag => (record.tags || []).includes(tag)),
    },

    /**
     * Note: The 'cost' is high so that we can 'undo' this filter for the counter per category
     */
     category: {
      cost: 9999,
      id: 'category',
      label: 'Category',
      position: '',
      componentName: null,
      hidden: true,
      options: [{
          text: 'Alles',
          value: 'all',
          reset: true,
          layout: 'default',
        }, {
          text: 'Open',
          value: 'open',
          layout: 'default',
        }, {
          text: 'Geaccepteerd',
          value: 'accepted',
          layout: 'default',
        }, {
          text: 'Afgewezen',
          value: 'rejected',
          layout: 'rejected',
        }, {
          text: 'Verwijderd',
          value: 'removed',
          layout: 'default',
        },
      ],
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback }),
        }
      },
      callback: ({ record, value }) => record.categories[value] === true,
    },
  },
}
