/* eslint-disable no-unused-vars */

import { periodInDays } from '@/helpers/date';
import { commonFilterWrapper } from '../utils';
import store from '@/store';
import { allMunicipalityOptions } from '@/services/allMunicipalityOptions';

const isAmsterdam = process.env.VUE_APP_TENANT === 'amsterdam';
const columns = `150px ${isAmsterdam ? '4fr minmax(240px, 4fr)' : '5fr 240px'} 150px 110px 130px 140px 180px`;
const amsterdamFilters = {
  filters: ['reference', 'address', 'step', 'tags', 'processtype', 'vkb'],
  columns: '150px 2fr 240px 1fr 120px 130px',
  area: '"reference address step tags processtype vkb"'
};

const realisationProcessStatusTranslations = {
  completed: 'Afgerond',
  cancelled: 'Geannuleerd',
  onhold: 'On hold'
};

const vkbStatuses = ({ record, Location }) => {
  const now = Date.now();

  let vkb = 'Onbekende status';
  let vkb_sorting = 9999;

  if (record.status.vkb) {
    vkb = 'Onherroepelijk';
    vkb_sorting = 1;
  } else if (Location) {
    // Location
    const TrafficDecisionDetails = Location.getTrafficDecisionDetails();
    if (TrafficDecisionDetails.TrafficDecisionNotApplicable) {
      vkb = 'Geen VKB';
      vkb_sorting = 99;
    } else if (!TrafficDecisionDetails.PublicationDate) {
      vkb = 'Nog te publiceren';
      vkb_sorting = 100;
    } else if (TrafficDecisionDetails.ConfirmedDate) {
      vkb = 'Onherroepelijk';
      vkb_sorting = 1;
    } else if (TrafficDecisionDetails.DefinitiveDate) {
      const date = new Date(TrafficDecisionDetails.DefinitiveDate).getTime();

      // Already passed
      if (date < now) {
        vkb = 'Verlopen';
        vkb_sorting = 2;
      } else {
        const diffInMs = date - now;
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        const diffInWeeks = diffInDays / 7;

        if (diffInDays < 2) {
          vkb = 'Nog max 2 dagen';
          vkb_sorting = 3;
        } else if (diffInDays < 7) {
          vkb = 'Nog max 7 dagen';
          vkb_sorting = 4;
        } else if (diffInDays <= 14) {
          vkb = 'Nog max 14 dagen';
          vkb_sorting = 5;
        } else if (diffInWeeks >= 2 && diffInWeeks < 4) {
          vkb = 'Nog 2-4 weken';
          vkb_sorting = 6;
        } else if (diffInWeeks >= 4 && diffInWeeks <= 6) {
          vkb = 'Nog 4-6 weken';
          vkb_sorting = 7;
        } else if (diffInWeeks > 6) {
          vkb = 'Nog meer dan 6 weken';
          vkb_sorting = 8;
        }
      }
    }
  }

  return { vkb, vkb_sorting };
};

const DDMMYYYY = ({ date }) => {
  return new Date(date).toLocaleDateString('es-CL');
};

const stepNumbersByEditor = () => {
  const steps = store.getters['tenant/getSteps'];

  return steps.reduce((result, step) => {
    (step?.access?.edit || []).forEach(editor => {
      result[editor] = result[editor] || [];
      result[editor].push(step.step || step.short);
    });

    return result;
  }, {});
};

const stepByUuid = uuid => {
  const steps = store.getters['tenant/getSteps'];
  return steps.find(step => step.uuid === uuid);
};

export default {
  /**
   * Config reference
   * @type {string}
   */
  uuid: '3406eb6e-f42a-4e9d-914a-5a11e308f867',

  /**
   * Use to group table configurations
   *  Configs for realisation index should not be used for the statistics dashboard
   */
  category: 'realisation',

  /**
   * Access requirements
   */
  access: {
    /**
     * Required access checks. Must pass all
     */
    requirements: [],

    // TODO: Maybe specicify exclude list? If you have one config, exclude this one
    supersededBy: []
  },

  /**
   *
   */
  controlsComponentName: 'RealisationControls',

  // Minimal width of the table
  minWidth: '920px',
  maxWidth: '1900px',
  pageSize: 10,
  visualisation: 'table',

  /**
   * CSS Grid config
   *
   * @type {object}
   */
  layouts: {
    default: {
      id: 'default',
      label: 'Standaard',
      filters: {
        // TODO: Put this layouts object in the config (tenant) //
        ...(isAmsterdam
          ? { ...amsterdamFilters }
          : {
            filters: ['reference', 'address', 'municipality', 'step', 'tags', 'processtype', 'vkb'],
            columns: '150px 2fr 240px 1fr 120px 130px',
            area: '"reference address municipality step tags processtype vkb"'
          })
      },
      header: {
        // copied to row
        properties: [
          'reference',
          'address',
          'step',
          'progress_days',
          'requestCount',
          'tags',
          'processtype',
          'updated_at',
          'vkb'
        ],
        columns,
        area: '"reference address step progress_days requestCount tags processtype vkb"',
        hidden: ['updated_at']
      }
    },
    export: {
      id: 'export',
      label: 'Oude weergave',
      filters: {
        ...(isAmsterdam
          ? { ...amsterdamFilters }
          : {
            filters: ['reference', 'address', 'municipality', 'step', 'tags', 'processtype', 'vkb'],
            columns: '150px 2fr 240px 1fr 120px 130px',
            area: '"reference address municipality step tags processtype vkb"'
          })
      },
      header: {
        // copied to row
        properties: ['reference', 'address', 'step', 'requestCount', 'tags', 'processtype', 'updated_at', 'vkb'],
        columns: '150px 4fr minmax(240px, 4fr) 110px 130px 130px 150px 180px',
        area: '"reference address step requestCount tags processtype updated_at vkb"',
        hidden: []
      }
    }
  },

  export: [
    {
      btn: {
        label: 'Excel', // Used for render CTA's (action buttons) //
        variant: 'success'
      },
      file: {
        name: 'Realisatieprocessen',
        acronym: 'RP'
      },
      worksheetColumns: [
        { header: 'Referentie', key: 'reference', width: 18 },
        { header: 'Adres', key: 'address', width: 50 },
        { header: 'Gemeente', key: 'municipalityName', width: 25 },
        { header: 'Latitude', key: 'lat', width: 22 },
        { header: 'Longitude', key: 'lng', width: 22 },
        { header: 'Parkeervakken', key: 'parkinglotIds', width: 25 },
        { header: 'Stap', key: 'step', width: 32 },
        { header: 'In deze stap', key: 'progress_days', width: 15 },
        { header: 'Status', key: 'currentStatus', width: 15 },
        { header: 'Laadpaal status', key: 'chargingpointStatus', width: 15 },
        { header: 'Onhold & Annulerings reden', key: 'currentStatusMessage', width: 35 },
        { header: 'Aanvragen', key: 'requestCount', width: 15 },
        { header: 'Type', key: 'processtype', width: 20 },
        { header: 'Aantal laadpalen', key: 'numberOfChargingStations', width: 20 },
        { header: 'VKB Status', key: 'vkb', width: 25 },
        { header: 'Datum VKB publicatie', key: 'VKBPublicationDate', width: 25 },
        { header: 'Datum VKB definitief', key: 'VKBDefinitiveDate', width: 25 },
        { header: 'Netbeheerdernummer', key: 'gridOperatorNumber', width: 25 },
        { header: 'Aansluitwaarde', key: 'connectionValue', width: 20 },
        { header: 'EAN', key: 'EAN', width: 20 },
        { header: "Laadpaal ID's", key: 'chargerIds', width: 20 },
        { header: 'Uitvoering laadpaal', key: 'chargingStationVersion', width: 28 },
        { header: 'Aannemer', key: 'contractor', width: 20 },
        { header: 'Realisatie startdatum', key: 'realisationStartDate', width: 25 },
        { header: 'Datum aanvraag netaansluiting', key: 'gridConnectionDate', width: 32 },
        { header: 'Geplande realisatiedatum', key: 'realisation_date', width: 28 },
        { header: 'Gewijzigd op', key: 'updated_at', width: 20 },
        { header: 'Datum in bedrijf genomen', key: 'dateOfCommissioning', width: 30 },
        { header: 'Aantal tags', key: 'tagcount', width: 15 },
        { header: 'Tags', key: 'tags', width: 10 },
        { header: 'SPV', key: 'finUnit', width: 15 }
      ],
      formatRecords: records => {
        const requestCountGetter = store.getters['relations/requestUuidsByRealisationUuid'];
        const processTypeLabelGetter = store.getters['tenant/getSelectOptionLabelByValue'];
        const getContractorByUuidGetter = store.getters['tenant/getContractorByUuid'];
        const baseUrl = store.getters['tenant/getBaseUrl'];

        return records
          .map(record => {
            record.reference = {
              text: record.reference,
              url: `${baseUrl}/realisatie/${record.requestCount}` // record uuid
            };
            // Append description to tags and progress_days props //
            record.requestCount = requestCountGetter({ uuid: record.requestCount }).length;
            record.processtype = processTypeLabelGetter({
              name: 'processCategories',
              value: record.processtype
            });
            //record.tagcount = `${record.tagcount}`

            record.tags = store.getters['tenant/getRecordTagLabels']({ record }).join(', ');
            record.updated_at = DDMMYYYY({ date: record.updated_at_nr });
            // Remove (municipality) from address for excel export. There is already a column for //
            record.address = record.address.split('(')?.[0].trim() || record.address.trim();

            // Get the contractor label by uuid
            record.contractor =
              getContractorByUuidGetter({
                uuid: record.contractor
              })?.label || '';
            // Render only days (number) in progress_days Excel column (remove statujs label)
            record.progress_days = typeof record.progress_days === 'number' ? record.progress_days : '';

            //record.realisation_date = record.realisation_date ? DDMMYYYY({ date: record.realisation_date, record }) : ''

            return record;
          })
          .sort((a, b) => b.reference_nr - a.reference_nr);
      }
    }
  ],

  headerLabels: {
    reference: 'Referentie',
    address: 'Adres',
    step: 'Stap',
    requestCount: 'Aanvragen',
    tags: 'Tags',
    processtype: 'Type',
    updated_at: 'Gewijzigd op',
    progress_days: 'In deze stap',
    vkb: 'VKB status'
  },
  cellComponents: {
    reference: null,
    address: null,
    step: 'Step',
    requestCount: 'RequestCount',
    tags: 'Tags',
    processtype: 'ProcessType',
    updated_at: 'UpdatedAt',
    progress_days: 'Progress',
    vkb: null
  },

  /**
   * sorting options
   *  Note that a header may be sorted by a different field than itself
   *  An example is the referencenr used to sort the reference column numerically
   */
  sorting: {
    default: {
      type: 'number',
      sortBy: 'reference_nr'
    },
    reference: {
      type: 'number',
      sortBy: 'reference_nr'
    },
    address: {
      type: 'string',
      sortBy: 'address'
    },
    step: {
      type: 'string',
      sortBy: 'step'
    },
    cpo: {
      type: 'string',
      sortBy: 'cpo'
    },
    tags: {
      type: 'number',
      sortBy: 'tagcount'
    },
    processtype: {
      type: 'string',
      sortBy: 'processtype'
    },
    updated_at: {
      type: 'number',
      sortBy: 'updated_at_nr'
    },
    progress_days: {
      type: 'number',
      sortBy: 'progress_days_nr'
    },
    vkb: {
      type: 'number',
      sortBy: 'vkb_sorting'
    }
  },

  /**
   * Take a record and convert it to the data we use for displaying / sorting / filtering records in this table
   * @type {Function}
   */
  dataMapper: record => {
    let cpo = '';
    let Location = record.Location || record.values.Location || null;
    const coordinates = {
      lat: Location.Location.Latitude,
      lng: Location.Location.Longitude
    };
    if (Location) {
      // Netbeheerder / Aannemer
      const GridOperator = Location.GridOperator;
      const Contractor = Location.Contractor;
      cpo = Location.ChargePointOperator; //`${GridOperator || '-'} / ${Contractor || '-'}`
    }

    // TODO: Step logic is to be replaced
    const step = stepByUuid(record.status.uuid);
    const stepNumber = step.step || step.short;
    const stepLabel = record.status.label //step.label;

    /**
     * VKB / TrafficDecision
     */
    const { vkb, vkb_sorting } = vkbStatuses({ record, Location });
    const realisation_date = Location.PlannedRealisation?.PlannedDate && Location.PlannedRealisation?.PlannedDate !== 'Cancelled'
      ? new Date(Location.PlannedRealisation?.PlannedDate).getTime()
      : null

    const VKBPublicationDate = Location.TrafficDecision.PublicationDate
      ? new Date(Location.TrafficDecision.PublicationDate).getTime()
      : null;

    const gridConnectionDate = Location.GridConnection?.RequestDate;

    let VKBDefinitiveDate;
    if (Location.TrafficDecision.DefinitiveDate) {
      VKBDefinitiveDate = new Date(Location.TrafficDecision.DefinitiveDate).getTime();
    } else if (vkb === 'Geen VKB') {
      const step5 = '252575b0-3ab6-4de3-82af-4cdd83ec6705';
      const step5CreatedDate = record.flow.state.find(step => step.uuid === step5)?.started_at;
      VKBDefinitiveDate = step5CreatedDate || '';
    }

    // Is active?
    const statuses = ['completed', 'cancelled', 'onhold'];
    const isActive = statuses.every(status => !record.status[status]);
    const currentStatus =
      !isActive && Object.keys(record.status).find(status => statuses.includes(status) && !!record.status[status]);

    const currentStatusMessage =
      [...store.getters['tenant/getOnHoldReasons'], ...store.getters['tenant/getCancelReasons']].find(
        reason => (record.statusMeta.cancelled.reason || record.statusMeta.onhold.reason) === reason.value
      )?.text ||
      record.statusMeta.cancelled.comment ||
      '';

    const chargingpointStatus = Location.Location.chargingpointStatus;

    // Progress
    let duration = record.getCurrentStepDuraction();
    let missingDurationInfo = duration.start === 0 || (duration.finished && duration.end === 0);

    let progress_days_nr = isActive && !missingDurationInfo ? periodInDays({ duration }) : -1;
    // TODO: Rename "progress_days" key name to something more generic, it can now hold a RP status label instead of number of days //
    let progress_days =
      isActive && !missingDurationInfo
        ? Number(progress_days_nr)
        : realisationProcessStatusTranslations[currentStatus] || '-';

    // Municipality is set at the Record level.
    const municipality = record.MunicipalityCode || record.Municipality || 'Onbekend';
    const municipalityName = (process.env.VUE_APP_TENANT === 'vlaanderen'
      ? store.getters['tenant/getMunicipalityOptions']
      : allMunicipalityOptions).find(o => o.value === municipality)?.text

    const [startDateEvent] = record.Events;
    const realisationStartDate = startDateEvent ? startDateEvent.ts : '';

    let cancelledStartEventTimeStamp = undefined;
    let totalOnholdTime = 0;
    // eslint-disable-next-line no-unused-vars
    let onHoldStart = 0;

    record.Events.map(event => {
      if (event.type === 'cancelled-start') cancelledStartEventTimeStamp = event.ts;
      if (event.type === 'cancelled-end') cancelledStartEventTimeStamp = 0;
      if (event.type === 'onhold-start') onHoldStart = event.ts;
      if (event.type === 'onhold-end') {
        totalOnholdTime += event.ts - onHoldStart;
        onHoldStart = 0;
      }
    });

    const EAN = Location?.DateOfCommissioning?.EAN;
    const dateOfCommissioning = Location.DateOfCommissioning.DateOfCommissioning;
    const chargerIds = Location.ChargerIds.map(c => c.Identifier).join(', ');
    const processType = record.processType.value

    // PNC FinUnit
    const finUnit = Location.FinUnit;
    const parkinglotIds = Location.ParkingSpots.Identifiers.map(spot => spot.Identifier).join(', ');

    return {
      reference: record.case_ref,
      // used for sorting the reference column
      reference_nr: record.raw.case.counter,
      uuid: record.uuid,

      address: record.address,
      municipality,

      tags: Array.isArray(record.Tags) ? record.Tags : [],
      tagcount: Array.isArray(record.Tags) ? record.Tags.length : 0,

      gridOperatorNumber: Location?.GridConnection?.GridOperatorNumber,
      connectionValue: Location?.GridConnection?.ConnectionValue,

      EAN,
      dateOfCommissioning,
      contractor: Location?.Contractor,

      category: record.getCategory() || '-',

      step: `${stepNumber} ${record.status.completed ? 'Afgerond' : stepLabel}`,
      processType,

      // uuid is passed to render component to obtain relation count
      requestCount: record.uuid,
      chargerIds,
      cpo,
      updated_at: record.updated_at_short,
      updated_at_nr: record.raw.updated_at,
      updated_at_ts: record.raw.updated_at,
      VKBPublicationDate,
      VKBDefinitiveDate,
      gridConnectionDate,
      realisation_date,

      // For Excel export //
      ...coordinates,
      municipalityName,
      postalCode: Location.Location.PostalCode,
      street: Location.Location.StreetName,
      houseNumber: Number(Location.Location.HouseNumber),
      houseNumberSuffix: Location.Location.HouseNumberSuffix,
      city: Location.Location.CityName,
      country: Location.Location.Country,

      numberOfChargingStations: Number(record.CurrentLocation?.ParkingSpots?.NumberOfChargingStations),
      // uitvoering laadpaal
      chargingStationVersion: record.CurrentLocation?.CPOAdvice?.ChargingStationVersion || '',
      powerType: record.CurrentLocation?.CPOAdvice?.PowerType || '',

      // progress
      progress_days,
      progress_days_nr,
      currentStatus: realisationProcessStatusTranslations[currentStatus] || 'Actief',
      currentStatusMessage,
      chargingpointStatus,
      flow: record.flow,
      requestUuids: record.requestUuids,

      // Realisation start date
      realisationStartDate,
      cancelledStartEventTimeStamp,
      totalOnholdTime,

      // Turns row into a link
      route: {
        name: `realisationStep${stepNumber}`,
        params: {
          uuid: record.uuid,
          step: stepNumber
        }
      },

      /**
       * Note: using dutch category names because these are also used as slugs
       */
      categories: {
        all: true,
        active: isActive,

        municipality:
          !record.status.completed &&
          !record.status.cancelled &&
          (stepNumbersByEditor()['municipality'] || []).includes(parseInt(record.status.step)),
        cpo:
          !record.status.completed &&
          !record.status.cancelled &&
          (stepNumbersByEditor()['cpo'] || []).includes(parseInt(record.status.step)),

        onhold: record.status.onhold === true && record.status.cancelled !== true,
        completed: record.status.completed === true,
        cancelled: record.status.cancelled === true
      },

      /**
       * Filter params
       */
      filteredOut: false,
      filterReason: null,

      /**
       * Traffic Decision
       */
      vkb,
      vkb_sorting,

      /* PNC FinUnit */
      finUnit,
      /* AMS parkeervakken */
      parkinglotIds
    };
  },

  /**************************************************************************
   * Filter configuration
   *
   * @type {object[]}
   */
  filters: {
    reference: {
      cost: 2,
      id: 'reference',
      label: 'Referentie',
      position: '',
      componentName: 'SingleStringFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          value: filter.filters[0].value.toLowerCase(),
          callback: commonFilterWrapper({ callback: filter.callback })
        };
      },
      callback: ({ record, value }) => record.reference.toLowerCase().includes(value)
    },

    step: {
      cost: 1,
      id: 'step',
      label: 'Stap',
      position: '',
      componentName: 'StepFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        };
      },
      callback: ({ record, value }) => record.step === value
    },

    address: {
      cost: 20,
      id: 'address',
      label: 'Adres',
      position: '',
      componentName: 'SingleStringFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value.toLowerCase(),
          callback: commonFilterWrapper({ callback: filter.callback })
        };
      },
      callback: ({ record, value }) => record.address.toLowerCase().includes(value)
    },
    municipality: {
      cost: 5,
      id: 'municipality',
      label: 'Gemeente',
      position: '',
      componentName: 'MunicipalityFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        };
      },
      callback: ({ record, value }) => record.municipality === value
    },

    tags: {
      cost: 50,
      id: 'tags',
      label: 'Tags',
      position: '',
      componentName: 'TagSelectFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          value: filter.filters.map(filter => filter.value),
          callback: commonFilterWrapper({ callback: filter.callback })
        };
      },
      callback: ({ record, value }) => value.every(tag => (record.tags || []).includes(tag))
    },

    processtype: {
      cost: 5,
      id: 'processtype',
      label: 'Type',
      position: '',
      componentName: 'ProcessTypeFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        };
      },
      callback: ({ record, value }) => record.processtype === value
    },

    vkb: {
      cost: 5,
      id: 'vkb',
      label: 'VKB status',
      position: '',
      componentName: 'VKBSelectFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        };
      },
      callback: ({ record, value }) => record.vkb === value
    },

    /**
     * Note: The 'cost' is high so that we can 'undo' this filter for the counter per category
     */
    category: {
      cost: 9999,
      id: 'category',
      label: 'Category',
      position: '',
      componentName: null,
      hidden: true,
      options: [
        {
          text: 'Alles',
          value: 'all',
          reset: true,
          route: {
            name: 'RealisationList'
          }
        },
        {
          text: 'Actief',
          value: 'actief',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'actief' }
          }
        },
        {
          text: 'Gemeente',
          value: 'gemeente',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'gemeente' }
          }
        },
        {
          text: 'CPO',
          value: 'cpo',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'cpo' }
          }
        },
        {
          text: 'On hold',
          value: 'onhold',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'onhold' }
          }
        },
        {
          text: 'Afgerond',
          value: 'afgerond',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'afgerond' }
          }
        },
        {
          text: 'Geannuleerd',
          value: 'geannuleerd',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'geannuleerd' }
          }
        }
      ],
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        };
      },
      callback: ({ record, value }) => record.categories[value] === true
    }
  }
};
