import { noFilter } from '../utils.js'

export default {

  /**
   * Config reference
   * @type {string}
   */
  uuid: '9632abf9-362d-4faa-a27b-d9b725dfa6c4',

  /**
   * Use to group table configurations
   *  Configs for realisation index should not be used for the statistics dashboard
   */
  category: 'goral-locations-map',

  /**
   * Access requirements
   */
  access: {
    requirements: [],
    supersededBy: [],
  },

  /**
   *
   */
  controlsComponentName: 'GORALLocationControls',


  // Minimal width of the table
  minWidth: '0',
  maxWidth: '1900px',
  pageSize: 1000,
  pageSizeOptions: [1000],
  visualisation: 'slot',
  settings: false,

  /**
   * CSS Grid config
   *
   * @type {object}
   */
  layouts: {
    default: {
      id: 'default',
      label: 'Standaard',
      filters: {
        filters: [],
        columns: '',
        area: '',
      },
      header: { // copied to row
        properties: [],
        columns: '',
        area: '',
      },
    },
  },

  headerLabels: {},
  cellComponents: {},

  /**
   * sorting options
   *  Note that a header may be sorted by a different field than itself
   *  An example is the referencenr used to sort the reference column numerically
   */
  sorting: {
    default: {
      type: 'number',
      sortBy: 'position',
    },
  },


  /**
   * Take a record and convert it to the data we use for displaying / sorting / filtering records in this table
   * @type {Function}
   */
  dataMapper: (record) => record,

  /**
   * Data can be aggregated
   */
  dataPresentationMapper: false,
  totalMapper: false,


  /**************************************************************************
   * Filter configuration
   *
   * @type {object[]}
   */
  filters: {

    /**
     * Note: The 'cost' is high so that we can 'undo' this filter for the counter per category
     */
    proactive: {
      cost: 100,
      id: 'proactive',
      label: 'Proactief',
      position: '',
      componentName: null,
      hidden: false,
      options: [{
        text: 'Geen',
        value: 'none',
      }, {
        text: '2022-1',
        value: '2022-1',
      }, {
        text: '2022-1 extra',
        value: '2022-1 extra',
      }, {
        text: '2022-2',
        value: '2022-2',
      }, {
        text: '2022-2 extra',
        value: '2022-2 extra',
      }, {
        text: '2023-1',
        value: '2023-1',
      }, {
        text: '2023-1 extra',
        value: '2023-1 extra',
      }, {
        text: '2023-2',
        value: '2023-2',
      }, {
        text: '2023-2 extra',
        value: '2023-2 extra',
      }, {
        text: '2024-1',
        value: '2024-1',
      }, {
        text: '2024-1 extra',
        value: '2024-1 extra',
      }],
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: noFilter,
        }
      },
      callback: () => {},
    },
    municipalities: {
      cost: 20,
      id: 'municipalities',
      label: 'Gemeente',
      position: '',
      componentName: null,
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: noFilter,
        }
      },
      callback: () => {},
    },
    status: {
      cost: 20,
      id: 'status',
      label: 'Status',
      position: '',
      componentName: null,
      hidden: false,
      options: [{
        text: 'Gevalideerde locatie',
        value: 'definitive',
      }, {
        text: 'Afgewezen locatie',
        value: 'rejected',
      }, {
        text: 'In voorbereiding',
        value: 'in-progress',
      }, {
        text: 'Bestaande laadpaal',
        value: 'realized',
      }, {
        text: 'Voorgestelde locatie',
        value: 'suggestion',
      }],
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: noFilter,
        }
      },
      callback: () => {},
    },
  },

}
