<template>
  <div class="BrandLogo" :class="`BrandLogo--${img}`" :style="{ width: `${width}px` }">
    <img src="../assets/image/evtools.png" :width="width" alt="" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    context: {
      type: String,
      default: 'login'
    }
  },
  computed: {
    ...mapGetters('tenant', ['getTenant']),
    img() {
      return this.context === 'login' ? 'evtools' : this.getTenant.slug;
    },
    width() {
      return 130;
    },
    color() {
      return 'none';
    }
  }
};
</script>
