<template>
  <div style="display:none" />
</template>

<script>
/* eslint-disable */

import MapBoxMixin from '@/components/common/MapBoxMixin'
import { isValidLatitude, isValidLongitude } from '@/services/validation'
import { visibleRequestsByRealisationStatuses, requestMapShowActiveRedMarker } from '@/config'
import { mapActions, mapGetters } from 'vuex'

/**
 * This layer shows the Request locations
 */
export default {
  name: 'LayerRequests',
  mixins: [ MapBoxMixin ],
  props: {
    /**
     * The active Request
     *  Used on the Request detail page
     */
    uuid: {
      type: String,
      default: null
    },

    /**
     * The selected Requests
     *  Used on the LocationSelection page
     */
    realisationUuid: {
      type: String,
      default: null
    },

    /**
     * Whether to use isochrone instead of circle
     */
    showRadius: {
      type: Boolean,
      default: true
    },
    coordinates: {
      type: Object,
      default: null
    },
    municipalityCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      /**
       * Icon counter. We're loading 5 icons (see requiredIcons)
       */
      iconCounter: 0,
      requiredIcons: [
        'active',   // the active Request
        'open',     // requests with open status
        'accepted', // requests with accepted status, with no connection
        'selected', // requests that are selected to be connected to the active location proposal
        'connected', // requests that are connected to a location proposal
        'completed' // requests with completed realisation
      ],
      layerName: 'requests',

      delayedRepaint: null,
      requestsSet: new Set(),
      currentMunicipality: null
    }
  },
  computed: {
    ...mapGetters('requests', ['requests', 'requestsByRealisationStatus', 'requestsByCompletedRealisation', 'requestsByCode']),
    ...mapGetters('realisations', ['records']),
    ...mapGetters('relations', ['requestUuidsByRealisationUuid', 'realisationUuidByRequestUuid', 'changeCount']),

    /**
     * Track whether all required icons are loaded
     */
    iconsLoaded() {
      return this.iconCounter >= this.requiredIcons.length
    },

    shownRequest() {
      return this.requestsSource.filter(request => {
        // uuid matches active request, or the request is open or accepted
        return request.uuid === this.uuid || [2, 3].includes(request.status)
      })
    },
    activeRequest() {
      return this.shownRequest.find(request => request.uuid === this.uuid)
    },
    requestsSource () {
      return (this.realisationUuid && !this.isTenantMunicipality) 
        ? this.requestsByCode({ code: this.municipalityCode })
        : this.requests
    },
    isTenantMunicipality () {
      return ['0363'].includes(this.municipalityCode)
    }
  },
  watch: {
    /**
     * Trigger the loading the icons when mapbox is ready
     *  When switching styles mapbox 'unloads', and we reset the icon counter as we will need to reload them
     */
    loaded(ready) {
      if (ready === false) {
        this.iconCounter = 0
      } 
      if (ready) {
        this.init()
      }
    },

    /**
     * When the icons are loaded we can move on to adding the layer
     */
    iconsLoaded(ready) {
      if (ready) {
        if (this.coordinates) {
          this.addActiveLayer()
        }
        this.addLayer()
      }
    },
    changeCount() {
      this.updateLayerData()
    },
    realisationUuid() {
      this.updateLayerData()
    },
    /**
     * If coordinates (request map) are changed, update layer data
     */
    coordinates: {
      deep: true,
      handler () {
        this.updateActiveLayerData()
      }
    },
    municipalityCode: {
      immediate: true,
      handler (code) {
        this.loadRequestsByCompletedRealisation({ code })
        this.updateLayerData()
      }
    },
    requests: {
      deep: true,
      handler () {
        this.updateLayerData()
      }
    }
  },
  created() {
    if (this.loaded) {
      this.init()
    }
  },
  beforeDestroy() {
    this.clearLayer()
    this.clearActiveLayer()
  },
  methods: {
    ...mapActions('requests', ['loadRequestsByCompletedRealisation' ]),
    
    /********************************************************************************
     * Setup
     */

    init() {
      // Load the layer icons
      this.loadIcons()
    },

    /**
     * Make the marker icons available to MapBox
     *  This must be done before adding the layer
     */
    loadIcons() {
      this.requiredIcons.forEach(name => {
        this.map.loadImage(
          require(`@/assets/image/legend/request-${name}.png`),
          (err, image) => {
            if (err) throw err;

            if (! this.map.hasImage(`request-${name}`)) {
              this.map.addImage(`request-${name}`, image);
            }
            this.iconCounter++
        })
      })
    },

    /********************************************************************************
     * The events
     */

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.map.getCanvas().style.cursor = ''
    },

    /**
     * Emit a click event upon clicking a request icon
     */
    handleClickMarkerEvent(e) {
      if (! e.features.length) return;

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      this.$emit('click', { uuid: e.features[0].properties.uuid })
    },


    /********************************************************************************
     * The Layer
     */

    /**
     * Translate request requests into GeoJSON
     */
    generateGeoJson() {
      const requests =  Array.isArray(this.shownRequest) ? this.shownRequest : []
      this.requestsSet.clear()
      let selected = this.requestUuidsByRealisationUuid({
        uuid: this.realisationUuid
      }) || []

      const featureCollection = {
        "type": "FeatureCollection",
        "features": requests.map(request => {
          let icon = 'open'
          const latitude = request.coordinates.lat
          const longitude = request.coordinates.lng
          
          if (selected.includes(request.uuid)) {
            icon = 'selected'
          } else if (this.realisationUuidByRequestUuid({ uuid: request.uuid })) {
            icon = 'connected'
          } else if (request.status === 2) {
            icon = 'open'
          } else if (request.status === 3) {
            icon = 'accepted'
          } 
          if (this.requestsByCompletedRealisation.some(rp => rp.requestUuids.includes(request.uuid))) {
            icon = 'completed'
          }
          this.requestsSet.add(icon === 'selected' ? 'connectedToSelectedRP': icon)
          
          return {
            "type": "Feature",
            "properties": {
              "uuid": request.uuid,
              "icon": `request-${icon}`,
              "reference": request.case_ref,
              "size": 0.05
            },
            "geometry": {
              "type": "Point",
              "coordinates": [longitude, latitude]
            }
          }
        })
      }
      
      if (this.municipalityCode && this.municipalityCode !== this.currentMunicipality) {
        this.$store.dispatch('chargingpoints/loadActiveFilterItems', 
          { 
            filterType: 'requests',
            filterItems: Array.from(this.requestsSet),
            municipalityCode: this.municipalityCode
          }
        )
        this.currentMunicipality = this.municipalityCode
      }

      return featureCollection
    },

    generateGeoJsonActiveLayer () {
      const latitude = this.coordinates?.Latitude
      const longitude = this.coordinates?.Longitude

      return {
        "type": "FeatureCollection",
        "features": [
           {
            "type": "Feature",
            "properties": {
              "uuid": this.activeRequest.uuid,
              "icon": 'request-active',
              "reference": this.activeRequest.case_ref,
              "size":  0.1 
            },
            "geometry": {
              "type": "Point",
              "coordinates": [longitude, latitude]
            }
          }
        ]
      }
    },

    updateLayerData () {
      if (! this.loaded) return
      let source = this.map.getSource(this.layerName)

      if (! source) return
      const layer = this.generateGeoJson()
      source.setData(layer)
    },

    updateActiveLayerData () {
      if (! this.loaded) return
      const source = this.map.getSource(`${this.layerName}-active`)

      if (! source) return
      const layer = this.generateGeoJsonActiveLayer()
      source.setData(layer)
    },

    /**
     * Source: https://stackoverflow.com/a/37794326
     */
    metersToPixelsAtMaxZoom({ meters, latitude }) {
      return meters / 0.075 / Math.cos(latitude * Math.PI / 180)
    },


    /**
     * Source: https://stackoverflow.com/a/37794326
     */
    metersToPixelsAtMaxZoom({ meters, latitude }) {
      return meters / 0.075 / Math.cos(latitude * Math.PI / 180)
    },

    /**
     * Load the request layer
     */
    addLayer() {
      if (! this.map) return

      this.clearLayer()

      // Add the generated source 
      this.map.addSource(this.layerName, {
        type: 'geojson',
        data: this.generateGeoJson()
      })

      let currentLayers = this.map.getStyle().layers.map(layer => layer.id)
      let positionBelow = ['locationpin']
      positionBelow = positionBelow.reduce((result, layer) => {
        return result ? result : (currentLayers.includes(layer) ? layer : null)
      }, null)

      // Add icons layer within addLayer only if on realisation view //
        this.map.addLayer({ 
          "id": this.layerName,
          "type": "symbol",
          "source": this.layerName,
          "minzoom": 10,
          "layout": {
            "symbol-sort-key": 1,
            "symbol-placement": "point",
            "symbol-z-order": "source",
            "icon-allow-overlap": true,
            "icon-image": ['get', 'icon'], 
            "icon-size": [
              "interpolate",
              ["linear"],
              ["zoom"],
              10,
              ['get', 'size'],
              18,
              0.2
            ],
            "icon-offset": [0, -125]
          },
          "paint": {
            // "icon-color": "",
            "icon-opacity": [ // transition based on zoom
              "interpolate",
              ["linear"],
              ["zoom"],
              10,
              0,
              10.2,
              1
            ]
          }
        }, positionBelow)

      /**
       * Add the ID next to definitive charging points
       */
      this.map.addLayer({ 
        "id": `${this.layerName}-text`,
        "type": "symbol",
        "source": this.layerName,
        "minzoom": 14,
        "layout": {
          "symbol-sort-key": 1,
          "symbol-placement": "point",
          "symbol-z-order": "source",
          // 'text-allow-overlap': true,
          'text-field': ['get', 'reference'],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ],
          "text-size": 11,
          "text-radial-offset": [ // transition based on zoom
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            1,
            18.2,
            2
          ],
          'text-variable-anchor': [ "top-right", "bottom-right", "bottom-left", "top-left", "right", "left", "top", "bottom" ],
          "text-justify": 'auto'
        },
        "paint": {
          "text-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            0,
            14.2,
            0.8
          ]
        }
      }, positionBelow) 
    },

    addActiveLayer () {
      if (! this.map) return

      this.clearActiveLayer()

      // Add the generated source 
      this.map.addSource(`${this.layerName}-active`, {
        type: 'geojson',
        data: this.generateGeoJsonActiveLayer()
      })

      if (this.activeRequest && this.showRadius && isValidLatitude(this.coordinates.Latitude)) {
        this.map.addLayer({ 
          "id": `${this.layerName}-circle`,
          "type": "circle",
          "source": `${this.layerName}-active`,
          "minzoom": 10,
          "layout": {},
          "paint": {
            "circle-radius": {
              stops: [
                [0, 0],
                [20, this.metersToPixelsAtMaxZoom({ 
                  meters: 200, 
                  latitude: this.coordinates.Latitude 
                })]
              ],
              base: 2
            },
            "circle-color": '#ec0000',
            "circle-opacity": 0.2
          }
        }) 
        this.map.setFilter(`${this.layerName}-circle`, ['==', ['get', 'icon'], 'request-active'])
      }

      if (requestMapShowActiveRedMarker) {
        this.map.addLayer({ 
          "id": `${this.layerName}-active`,
          "type": "symbol",
          "source": `${this.layerName}-active`,
          "minzoom": 10,
          "layout": {
            "symbol-sort-key": 1,
            "symbol-placement": "point",
            "symbol-z-order": "source",
            "icon-allow-overlap": true,
            "icon-image": ['get', 'icon'], 
            "icon-size": [
              "interpolate",
              ["linear"],
              ["zoom"],
              10,
              ['get', 'size'],
              18,
              0.2
            ],
            "icon-offset": [0, -125]
          },
          "paint": {
            // "icon-color": "",
            "icon-opacity": [ // transition based on zoom
              "interpolate",
              ["linear"],
              ["zoom"],
              10,
              0,
              10.2,
              1
            ]
          }
        })
      }
    },

    /**
     * Clean up the layer
     */
    clearLayer() {
      if (! this.map) return 

      // Clean up first if we must
      let source = this.map.getSource(this.layerName)
      if (source) {
        if (this.map.getLayer(this.layerName)) {
          this.map.removeLayer(this.layerName)
        }
        if (this.map.getLayer(`${this.layerName}-text`)) {
          this.map.removeLayer(`${this.layerName}-text`)
        }
        if (this.map.getLayer(`${this.layerName}-circle`)) {
          this.map.removeLayer(`${this.layerName}-circle`)
        }
        
        this.map.removeSource(this.layerName)
      }
    },

    clearActiveLayer() {
      if (! this.map) return 

      // Clean up first if we must
      let source = this.map.getSource(`${this.layerName}-active`)
      if (source) {
        if (this.map.getLayer(`${this.layerName}-circle`)) {
          this.map.removeLayer(`${this.layerName}-circle`)
        }
        if (this.map.getLayer(`${this.layerName}-active`)) {
          this.map.removeLayer(`${this.layerName}-active`)
        }
        
        this.map.removeSource(`${this.layerName}-active`)
      }
    }
  }
}
</script>

