/* eslint-disable no-unused-vars */

import { periodInDays } from '@/helpers/date';
import store from '@/store';
import { allMunicipalityOptions } from '@/services/allMunicipalityOptions';

const realisationProcessStatusTranslations = {
  completed: 'Afgerond',
  cancelled: 'Geannuleerd',
  onhold: 'On hold'
};

const vkbStatuses = ({ record, Location }) => {
  const now = Date.now();

  let vkb = 'Onbekende status';
  let vkb_sorting = 9999;

  if (record.status.vkb) {
    vkb = 'Onherroepelijk';
    vkb_sorting = 1;
  } else if (Location) {
    // Location
    const TrafficDecisionDetails = Location.getTrafficDecisionDetails();
    if (TrafficDecisionDetails.TrafficDecisionNotApplicable) {
      vkb = 'Geen VKB';
      vkb_sorting = 99;
    } else if (!TrafficDecisionDetails.PublicationDate) {
      vkb = 'Nog te publiceren';
      vkb_sorting = 100;
    } else if (TrafficDecisionDetails.ConfirmedDate) {
      vkb = 'Onherroepelijk';
      vkb_sorting = 1;
    } else if (TrafficDecisionDetails.DefinitiveDate) {
      const date = new Date(TrafficDecisionDetails.DefinitiveDate).getTime();

      // Already passed
      if (date < now) {
        vkb = 'Verlopen';
        vkb_sorting = 2;
      } else {
        const diffInMs = date - now;
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        const diffInWeeks = diffInDays / 7;

        if (diffInDays < 2) {
          vkb = 'Nog max 2 dagen';
          vkb_sorting = 3;
        } else if (diffInDays < 7) {
          vkb = 'Nog max 7 dagen';
          vkb_sorting = 4;
        } else if (diffInDays <= 14) {
          vkb = 'Nog max 14 dagen';
          vkb_sorting = 5;
        } else if (diffInWeeks >= 2 && diffInWeeks < 4) {
          vkb = 'Nog 2-4 weken';
          vkb_sorting = 6;
        } else if (diffInWeeks >= 4 && diffInWeeks <= 6) {
          vkb = 'Nog 4-6 weken';
          vkb_sorting = 7;
        } else if (diffInWeeks > 6) {
          vkb = 'Nog meer dan 6 weken';
          vkb_sorting = 8;
        }
      }
    }
  }

  return { vkb, vkb_sorting };
};

const stepNumbersByEditor = () => {
  const steps = store.getters['tenant/getSteps'];

  return steps.reduce((result, step) => {
    (step?.access?.edit || []).forEach(editor => {
      result[editor] = result[editor] || [];
      result[editor].push(step.step || step.short);
    });

    return result;
  }, {});
};

const stepByUuid = uuid => {
  const steps = store.getters['tenant/getSteps'];
  return steps.find(step => step.uuid === uuid);
};

export default {
  dataMapper: record => {
    let cpo = '';
    let Location = record.Location || record.values.Location || null;
    const coords = Location.Location
    const coordinates = {
      lat: coords.Latitude,
      lng: coords.Longitude
    };
    if (Location) {
      // Netbeheerder / Aannemer
      // const GridOperator = Location.GridOperator;
      // const Contractor = Location.Contractor;
      cpo = Location.ChargePointOperator; //`${GridOperator || '-'} / ${Contractor || '-'}`
    }

    // TODO: Step logic is to be replaced
    const step = stepByUuid(record.status.uuid);
    const stepNumber = step.step || step.short;
    const stepLabel = step.label;

    /**
     * VKB / TrafficDecision
     */
    const { vkb, vkb_sorting } = vkbStatuses({ record, Location });
    const realisation_date = Location.PlannedRealisation?.PlannedDate && Location.PlannedRealisation?.PlannedDate !== 'Cancelled'
      ? new Date(Location.PlannedRealisation?.PlannedDate).getTime()
      : null

    const VKBPublicationDate = Location.TrafficDecision.PublicationDate
      ? new Date(Location.TrafficDecision.PublicationDate).getTime()
      : null;

    const gridConnectionDate = Location.GridConnection?.RequestDate;

    let VKBDefinitiveDate;
    if (Location.TrafficDecision.DefinitiveDate) {
      VKBDefinitiveDate = new Date(Location.TrafficDecision.DefinitiveDate).getTime();
    } else if (vkb === 'Geen VKB') {
      const step5 = '252575b0-3ab6-4de3-82af-4cdd83ec6705';
      const step5CreatedDate = record.flow.state.find(step => step.uuid === step5)?.started_at;
      VKBDefinitiveDate = step5CreatedDate || '';
    }

    // Is active?
    const statuses = ['completed', 'cancelled', 'onhold'];
    const isActive = statuses.every(status => !record.status[status]);
    const currentStatus =
      !isActive && Object.keys(record.status).find(status => statuses.includes(status) && !!record.status[status]);

    const currentStatusMessage =
      [...store.getters['tenant/getOnHoldReasons'], ...store.getters['tenant/getCancelReasons']].find(
        reason => (record.statusMeta.cancelled.reason || record.statusMeta.onhold.reason) === reason.value
      )?.text ||
      record.statusMeta.cancelled.comment ||
      '';

    const chargingpointStatus = Location.Location.chargingpointStatus;

    // Progress
    let duration = record.getCurrentStepDuraction();
    let missingDurationInfo = duration.start === 0 || (duration.finished && duration.end === 0);

    let progress_days_nr = isActive && !missingDurationInfo ? periodInDays({ duration }) : -1;
    // TODO: Rename "progress_days" key name to something more generic, it can now hold a RP status label instead of number of days //
    let progress_days =
      isActive && !missingDurationInfo
        ? Number(progress_days_nr)
        : realisationProcessStatusTranslations[currentStatus] || '-';

    // Municipality is set at the Record level.
    const municipality = record.MunicipalityCode || record.Municipality || 'Onbekend';

    const [startDateEvent] = record.Events;
    const realisationStartDate = startDateEvent ? startDateEvent.ts : '';

    let cancelledStartEventTimeStamp = undefined;
    let totalOnholdTime = 0;
    // eslint-disable-next-line no-unused-vars
    let onHoldStart = 0;

    record.Events.map(event => {
      if (event.type === 'cancelled-start') cancelledStartEventTimeStamp = event.ts;
      if (event.type === 'cancelled-end') cancelledStartEventTimeStamp = 0;
      if (event.type === 'onhold-start') onHoldStart = event.ts;
      if (event.type === 'onhold-end') {
        totalOnholdTime += event.ts - onHoldStart;
        onHoldStart = 0;
      }
    });

    const EAN = Location?.DateOfCommissioning?.EAN;
    const dateOfCommissioning = Location.DateOfCommissioning.DateOfCommissioning;
    const chargerIds = Location.ChargerIds.map(c => c.Identifier).join(', ');

    // PNC FinUnit
    const finUnit = Location.FinUnit;
    const parkinglotIds = Location.ParkingSpots.Identifiers.map(spot => spot.Identifier).join(', ');

    return {
      reference: record.case_ref,
      // used for sorting the reference column
      reference_nr: record.raw.case.counter,
      uuid: record.uuid,

      address: record.address,
      municipality,

      tags: Array.isArray(record.Tags) ? record.Tags : [],
      tagcount: Array.isArray(record.Tags) ? record.Tags.length : 0,

      gridOperatorNumber: Location?.GridConnection?.GridOperatorNumber,
      connectionValue: Location?.GridConnection?.ConnectionValue,

      EAN,
      dateOfCommissioning,
      contractor: Location?.Contractor,
      category: '',

      processType: record.processType.value,

      step: `${stepNumber} ${record.status.completed ? 'Afgerond' : stepLabel}`,

      // uuid is passed to render component to obtain relation count
      requestCount: record.uuid,
      chargerIds,
      cpo,
      updated_at: record.updated_at_short,
      updated_at_nr: record.raw.updated_at,
      updated_at_ts: record.raw.updated_at,
      VKBPublicationDate,
      VKBDefinitiveDate,
      gridConnectionDate,
      realisation_date,

      // For Excel export //
      ...coordinates,
      municipalityName: (process.env.VUE_APP_TENANT === 'vlaanderen'
      ? store.getters['tenant/getMunicipalityOptions']
      : allMunicipalityOptions).find(o => o.value === municipality)?.text,
      
      postalCode: Location.Location.PostalCode,
      street: Location.Location.StreetName,
      houseNumber: Number(Location.Location.HouseNumber),
      houseNumberSuffix: Location.Location.HouseNumberSuffix,
      city: Location.Location.CityName,
      country: Location.Location.Country,

      numberOfChargingStations: Number(record.CurrentLocation?.ParkingSpots?.NumberOfChargingStations),
      // uitvoering laadpaal
      chargingStationVersion: record.CurrentLocation?.CPOAdvice?.ChargingStationVersion || '',
      powerType: record.CurrentLocation?.CPOAdvice?.PowerType || '',

      // progress
      progress_days,
      progress_days_nr,
      currentStatus: realisationProcessStatusTranslations[currentStatus] || 'Actief',
      currentStatusMessage,
      chargingpointStatus,
      flow: record.flow,
      requestUuids: record.requestUuids,

      // Realisation start date
      realisationStartDate,
      cancelledStartEventTimeStamp,
      totalOnholdTime,
      
      categories: {
        all: true,
        active: isActive,

        municipality:
          !record.status.completed &&
          !record.status.cancelled &&
          (stepNumbersByEditor()['municipality'] || []).includes(parseInt(record.status.step)),
        cpo:
          !record.status.completed &&
          !record.status.cancelled &&
          (stepNumbersByEditor()['cpo'] || []).includes(parseInt(record.status.step)),

        onhold: record.status.onhold === true && record.status.cancelled !== true,
        completed: record.status.completed === true,
        cancelled: record.status.cancelled === true
      },

      /**
       * Traffic Decision
       */
      vkb,
      vkb_sorting,

      /* PNC FinUnit */
      finUnit,
      /* AMS parkeervakken */
      parkinglotIds
    };
  },
};
